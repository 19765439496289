import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

// i18n
import '../../i18n';
import { useTranslation } from 'react-i18next';

const CorpNavbar = () => {
  const [ t, i18n ] = useTranslation();
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  // const [navbarColor, setNavbarColor] = React.useState(" navbar-transparent");
  // const [buyButtonColor, setBuyButtonColor] = React.useState("neutral");
  const [navbarColor, setNavbarColor] = React.useState("");
  const [buyButtonColor, setBuyButtonColor] = React.useState("info");
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor(" navbar-transparent");
        setBuyButtonColor("neutral");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top" + navbarColor} color="white" expand="lg">
        <Container>
          <Nav>
            <NavItem>
              <NavLink
                className="active"
                href="/"
              >
                <b>{t("nav.company-name")}</b>
              </NavLink>
            </NavItem>
          </Nav>
          <Nav style={{marginRight: '115px'}}>
            <NavItem>
              <small>
              <p style={{lineHeight:'12px'}}>
                  TEL : <a href="tel:03-6451-3261">03-6451-3261</a><br />
                  EMAIL : sales@matscorp.com
              </p>
              </small>
            </NavItem>
          </Nav>
          <Nav className="justify-content-end">
            <NavItem>
              <NavLink
                className="active"
                href="/#business"
              >
                <b>{t("nav.business")}</b>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/stock/list"
              >
              <b>{t("nav.stock")}</b>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="active"
                href="/#company"
                // onClick={e => e.preventDefault()}
              >
                <b>{t("nav.aboutus")}</b>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="active"
                href="/greeting"
                // onClick={e => e.preventDefault()}
              >
                <b>{t("nav.greeting")}</b>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="active"
                href="/#access"
                // onClick={e => e.preventDefault()}
              >
                <b>{t("nav.access")}</b>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="active"
                href="/#inquiry"
                // onClick={e => e.preventDefault()}
              >
                <b>{t("nav.contact")}</b>
              </NavLink>
            </NavItem>
            <NavItem>
            {i18n.language==='ja' ? 
              <NavLink
                className="active"
                onClick={() => {i18n.changeLanguage('en')}}
              >
                <b>English</b>
              </NavLink>
            : 
              <NavLink
                className="active"
                onClick={() => {i18n.changeLanguage('ja')}}
              >
                <b>日本語(JA)</b>
              </NavLink>
            }
            </NavItem>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default CorpNavbar;
