import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardLink,
  CardHeader,
  UncontrolledTooltip,
  Label,
  ButtonGroup,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Spinner,
} from "reactstrap";

// core components
import CorpNavbar from "../../../components/Navbars/CorpNavbar.js";
import CorpHeader from "../../../components/Headers/CorpHeader.js";
import Footer from "../../../components/Footers/CorpFooter.js";

// component
import StockCard from "../../../components/card/StockCard.js";

// i18n
import '../../../i18n';
import { useTranslation } from 'react-i18next';

// SEO
import {Helmet} from "react-helmet";

// Amplify
import Amplify, { Auth, Storage, API, graphqlOperation } from "aws-amplify";
import { queryStockGsiOrderedList, sendEmail, queryNewsGsiOrderedList } from '../../../graphql/queries'
import { createContact } from '../../../graphql/mutations'
Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"});
// console.log('auth', Auth.currentAuthenticatedUser)
Auth.currentAuthenticatedUser()
  .then(data => {Amplify.configure({aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"}); console.log('user-pools')})
  .catch(err => {Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"}); console.log('iam')});

const initForm = {
  company: '',
  name: '',
  email: '',
  emailConfirm: '',
  tel: '',
  message: '',
  titleHead: '',
}


const IndexPage = () => {
  
  const [form, setForm] = React.useState(initForm);
  const [isSending, setIsSending] = React.useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [stocks, setStocks] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const [ t, i18n ] = useTranslation();
  
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);


  React.useEffect(() => {
    fetchStocks(); 
    fetchNews();

  }, []);

  const currentUTC = () => {
    let now = new Date();
    now.setTime(now.getTime() - 1000*60*60*9);// UCTへ変換
    let year = now.getYear(); // 年
    let month = now.getMonth() + 1; // 月
    let day = now.getDate(); // 日
    let hour = now.getHours(); // 時 (JST)
    let min = now.getMinutes(); // 分
    let sec = now.getSeconds(); // 秒
    
    if(year < 2000) { year += 1900; }
    
    // 数値が1桁の場合、頭に0を付けて2桁で表示
    // （例） 2020-12-11T15:30:00
    if(month < 10) { month = "0" + month; }
    if(day < 10) { day = "0" + day; }
    if(hour < 10) { hour = "0" + hour; }
    if(min < 10) { min = "0" + min; }
    if(sec < 10) { sec = "0" + sec; }
  
    return `${year}-${month}-${day}T${hour}:${min}:${sec}.000Z`
  };

  const sendMessage = async() =>{
    // console.log('send message', form)
    Auth.currentAuthenticatedUser()
      .then(data => {Amplify.configure({aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"}); console.log('user-pools')})
      .catch(err => {Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"}); console.log('iam')});

    try{
      // バリデーション
      let valMessage = ''
      if(form.company==='' || form.name==='' || form.email==='' || form.emailConfirm==='' || form.tel==='' || form.message==='' ){
        window.alert('全て入力してください');
        return
      }
      if(form.email !== form.emailConfirm){
        window.alert('メールアドレスが一致しません');
        return
      }
      // 送信
      setIsSending(true)
      // メール送信用API（gmail暫定版）
      try{
        const res = await API.graphql(graphqlOperation(sendEmail, {data: JSON.stringify(form)}))
        // console.log('res', res)
        const resData = JSON.parse(res.data.sendEmail)
        // メール送信は失敗しても、DB保存されればOKとする（暫定対応）
        // if(resData.statusCode !== 200){
        //   window.alert('送信に失敗しました。恐れ入りますが、もう一度やり直してください。');
        //   return
        // }
      }catch(err){
        console.log('err', err)
      }
      
      try{
        const inputContact = {
          company: form.company,
          name: form.name,
          email: form.email,
          tel: form.tel,
          message: form.message,
          receivedAt: currentUTC(),
          isDelete: 0
        }
        console.log('inputContact', inputContact)
        const resContact = await API.graphql(graphqlOperation(createContact, {input: inputContact}))
        // console.log('resContact', resContact)
        // [成功]メッセージ受付ダイアログ
        window.alert('お問い合わせありがとうございました。担当よりご連絡致します。');

      }catch(err){
        window.alert(`送信に失敗しました。
恐れ入りますが、 sales@matscorp.com までお問い合わせください。
        `);
        return
      }

    }catch(err){
      console.log('error', err)
      //　エラーダイアログ
      window.alert(`送信に失敗しました。
恐れ入りますが、 sales@matscorp.com までお問い合わせください。
      `);
      return
    }
    setIsSending(false)
    setForm(initForm)
  }

  const fetchStocks = async() =>{
      Auth.currentAuthenticatedUser()
        .then(data => {Amplify.configure({aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"}); console.log('user-pools')})
        .catch(err => {Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"}); console.log('iam')});
        
      try{
        let stockList = []
        let nextToken = null
        const res = await API.graphql(graphqlOperation(queryStockGsiOrderedList, {isPublish:1, sortDirection:"ASC", filter: {status: {eq: 'New'}}, limit: 4}))
        const items = res.data.queryStockGsiOrderedList.items
        for(const item of items){
          let stockItem = item
          try{
            const photoUrl = await Storage.get(item.imageMedium[0].key)
            stockItem['photoUrl'] = photoUrl
          }catch(err){
            stockItem['photoUrl'] = null
          }
          stockList.push(stockItem) 
        }
        nextToken = res.data.queryStockGsiOrderedList.nextToken

        while(nextToken!==null){
          const res = await API.graphql(graphqlOperation(queryStockGsiOrderedList, {isPublish:1, sortDirection:"ASC", nextToken: nextToken, filter: {status: {eq: 'New'}}, limit: 4 }))
          const items = res.data.queryStockGsiOrderedList.items
          for(const item of items){
            let stockItem = item
            try{
              const photoUrl = await Storage.get(item.imageMedium[0].key)
              stockItem['photoUrl'] = photoUrl
            }catch(err){
              stockItem['photoUrl'] = null
            }
            stockList.push(stockItem) 
          }
          nextToken = res.data.queryStockGsiOrderedList.nextToken
        }

        // console.log('stockList', stockList)
        setStocks(stockList)

      }catch(err){
        console.log('error', err)
      }
  }

  const fetchNews = async() =>{
    Auth.currentAuthenticatedUser()
      .then(data => {Amplify.configure({aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"}); console.log('user-pools')})
      .catch(err => {Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"}); console.log('iam')});
      
    try{
      const res = await API.graphql(graphqlOperation(queryNewsGsiOrderedList, {isPublish: 1, sortDirection:"DESC", limit: 3}))
      setNews(res.data.queryNewsGsiOrderedList.items)
    }catch(err){
      console.log('error', err)
    }
  }

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`松井商事株式会社`}</title>
          <meta name="description" content={`国内外の幅広いネットワークを活かし、中古建設機械の買取及び販売、レンタルを通じて、お客様のご満足を第一に事業を展開して参ります。`} />
          <meta name="keywords" content= {`松井商事株式会社,中古,中古建機,関東,茨城,かすみがうら,ショベル,ミニショベル,ブルドーザー,ホイールローダー`}/>
      </Helmet>
      <CorpNavbar />
      <div className="wrapper">
        <CorpHeader />
        <div className="section">
          <Container>
            <Row>
              <Col className="mr-auto mt-2" md="3">
                <a href="https://www.youtube.com/@matsui_corp" target="_blank">
                  <img
                    alt="..."
                    src={require("assets/img/sns/yt_logo_rgb_light.png")}
                    style={{paddingTop: 20}}
                  />
                </a>
              </Col>
              <Col className="mr-auto mt-2 text-center" md="6">
                <h2>News</h2>
                <div className="social-feed text-center">
                  <Table responsive borderless size="small">
                    {news.map((item, index) => {
                      return (
                        <tbody key={item.id} className="text-left">
                          <td style={{ padding: 0 }}>{`${item.publishAt} : `}</td>
                          <td style={{ padding: 0 }}>
                            {/* {item.message} */}
                            <div className="text" dangerouslySetInnerHTML={{__html: item.message}}></div>
                          </td>
                        </tbody>
                      )
                      })
                    }
                </Table>
                </div>
              </Col>
              <Col className="mr-auto mt-2" md="3">
                <a href="https://www.instagram.com/matsui__corp/" target="_blank">
                  <img
                    alt="..."
                    src={require("assets/img/sns/Instagram_Glyph_Gradient.png")}
                    style={{width: 100}}
                  />
                  </a>
              </Col>
            </Row>
          </Container>
          <hr />
          <div className="section related-products" data-background-color="">
            <Container>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{ color: 'black'}}>PICK UP</h2>
              </Col>
              <Row>
              {stocks.map((stock, index) => {
                  return (
                    <Col md="3" sm="6" key={index}>
                      <StockCard stock={stock} />
                    </Col>
                  )
                }
              )}
              </Row>
              <Col className="ml-auto mr-auto text-center" md="2">
                <Button
                  block
                  className="btn-round"
                  color="default"
                  onClick={() => {window.location.href = 'stock/list';}}
                >
                  在庫一覧へ
                  </Button>
              </Col>
            </Container>
          </div>
          <hr></hr>
          <div className="projects-5" id="business">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">事業概要</h2>
                <div className="section-space">
                  弊社は国内外の幅広いネットワークを活かし、中古建設機械の買取及び販売、レンタルを通じて、お客様のご満足を第一に事業を展開して参ります。
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mt-2" md="6">
                <div className="info-horizontal">
                  <div className="icon icon-warning">
                    <i className="now-ui-icons users_single-02"></i>
                  </div>
                  <div className="description">
                    <h3 className="info-title">中古建設機械の買取</h3>
                    <p className="description" style={{ color: '#525f7f'}}>
                    中古建設機械売買のプロとして、オーナー様にとって保有機械売却の最適な提案を行って参ります。 
                    使用環境、整備の行き届いた日本の中古建設機械は海外からの評価も高く、高い再販価値が見込めます。
                    全国津々浦々、弊社スタッフが機械買取のご相談に伺わせて頂きますので、お気軽にご相談下さい。
                    </p>
                  </div>
                </div>
                <div className="info-horizontal">
                  <div className="icon icon-warning">
                    <i className="now-ui-icons business_chart-bar-32"></i>
                  </div>
                  <div className="description">
                    <h3 className="info-title">中古建設機械の販売/輸出</h3>
                    <p className="description" style={{ color: '#525f7f'}}>
                    日本国内で調達した良質な中古建設機械を国内外問わず販売しております。 弊社のネットワークを活かして、東南アジア、中東、欧米、オセアニア等に有する海外顧客向けに日本製中古建設機械の輸出を行っております。 弊社営業が直接海外各国のお客様と日々コンタクトを取り交わし、海外への輸出まで全て行っております。また、日本国内のオーナー様向けの販売も行っております。お探しの機械が御座いましたらお気軽にご連絡下さい。
                    </p>
                  </div>
                </div>
                
              </Col>
              <Col className="mr-auto mt-2" md="6">
                <div className="info-horizontal">
                  <div className="icon icon-warning">
                    <i className="now-ui-icons arrows-1_refresh-69"></i>
                  </div>
                  <div className="description">
                    <h3 className="info-title">建設機械レンタル</h3>
                    <p className="description" style={{ color: '#525f7f'}}>
                    日本国内の建設現場において不足している機械の調達、レンタルの提案を行っております。 大型重機、発電機、建築用機械等、国内外における弊社ネットワークを活かして、国内施工基準に準じた必要な機材のご提案をさせて頂き、工事業者様の円滑な現場進行のサポートに努めております。 様々な形でのご提案が可能ですので、まずはお気軽にご相談下さい。
                    </p>
                  </div>
                </div>
                <div className="info-horizontal">
                  <div className="icon icon-danger">
                    <i className="now-ui-icons ui-2_chat-round"></i>
                  </div>
                  <div className="description">
                    <h3 className="info-title">海外市場進出へのコンサルティング、サポート</h3>
                    <p className="description" style={{ color: '#525f7f'}}>
                    東南アジアを中心とした土木・建築工事需要の高まりの中、海外進出をご検討されている土木・建築業者様のお手伝いをさせて頂いております。 海外工事案件における現地パートナーのご紹介、機械運用のサポートをしております。また、工事現場で使用される機械の現地調達のサポートも可能です。 ますますグローバル化が進む土木・建築業界の活性化を図る事で、効率的な提案を行い、日本の中古建設機械の可能性を広げて参ります。
                    </p>
                  </div>
                </div>
              </Col>
            </Row> 
          </Container>
        </div> 

        {/* 会社概要 */}
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage: "url(" + require("assets/img/project6.jpg") + ")",
          }}
          id="company"
        >
          <Container>  
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">会社概要</h2>
              </Col>
            </Row>
            <div className="cd-section" id="contentAreas">
            <div id="tables">
              <Row>
                <Col className="ml-auto mr-auto" md="12">
                  <Card className="card-plain">
                    <CardBody>
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>会社名</td>
                            <td>松井商事株式会社</td>
                          </tr>
                          <tr>
                            <td>代表取締役社長</td>
                            <td>松井　芳文</td>
                          </tr>
                          <tr>
                            <td>資本金</td>
                            <td>8,000,000円</td>
                          </tr>
                          <tr>
                            <td>設立</td>
                            <td>平成29年10月5日</td>
                          </tr>
                          <tr>
                            <td>住所</td>
                            <td>
                            本社 <br />〒142-0042 東京都品川区豊町4-5-23-203<br /><br />
                            TEL: 03-6451-3261 <br/>FAX: 03-6451-3262<br /><br />
                            かすみがうらサービスセンター  <br />
                            〒315-0057 茨城県かすみがうら市上土田964番地<br/><br />
                            TEL: 0299-57-1401 <br/>FAX: 0299-57-1402<br />
                            </td>
                          </tr>
                          <tr>
                            <td>事業内容 </td>
                            <td>
                            中古建設機械の買取<br />
                            中古建設機械の販売/輸出<br />
                            建設機械レンタル<br />
                            海外市場進出へのコンサルティング、サポート
                            </td>
                          </tr>
                          <tr>
                            <td>取引先銀行 </td>
                            <td>
                            三井住友銀行<br />
                            さわやか信用金庫<br />
                            川崎信用金庫
                            </td>
                          </tr>
                          <tr>
                            <td>古物商許可証 </td>
                            <td>東京都公安委員会許可 第302181707214号</td>
                          </tr>  
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Col className="ml-auto mr-auto text-center" md="12" id="access">
                <h2 className="title">アクセス</h2>
                <div className="content-center">
                  <h5 className="title">
                    Tel：<a href="tel:03-6451-3261"><b>03-6451-3261</b></a> <br /> 
                    Fax：03-6451-3262<br />
                  </h5>
                </div>
              </Col>
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <Card className="card-contact card-raised">
                    {/* 〒142-0062 東京都品川区小山6-13-2 2F */}
                    <CardBody>
                      本社: <br />〒142-0042 東京都品川区豊町4-5-23-203F<br /><br />
                      <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.8262310337755!2d139.71768937578443!3d35.607352772611144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188a918dce2993%3A0xf92516cf28bce76d!2z44CSMTQyLTAwNDIg5p2x5Lqs6YO95ZOB5bed5Yy66LGK55S677yU5LiB55uu77yV4oiS77yS77yT!5e0!3m2!1sja!2sjp!4v1720446905001!5m2!1sja!2sjp" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                      </div>
                    </CardBody>
                  </Card>
                  </Col>
                  <Col className="ml-auto mr-auto" md="6">
                  <Card className="card-contact card-raised">
                    <CardBody>
                      かすみがうらサービスセンター<br />
                      〒315-0057 茨城県かすみがうら市上土田964番地<br/><br />
                      <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3221.426852733493!2d140.24080111527195!3d36.15616618008631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x602213c228e53733%3A0xb0bb2320bc4ff881!2z44CSMzE1LTAwNTcg6Iyo5Z-O55yM44GL44GZ44G_44GM44GG44KJ5biC5LiK5Zyf55Sw77yZ77yW77yU!5e0!3m2!1sja!2sjp!4v1621002528439!5m2!1sja!2sjp" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          </Container>
          </div>
        </div>
        <div className="section section-contact-us text-center" id="inquiry">
          <Container>
            <h2 className="title">お問い合わせ</h2>
            <p className="description">お気軽にご連絡ください</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    firstFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons business_badge"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="企業名"
                    type="text"
                    value={form.company}
                    onChange={e => setForm({ ...form, 'company': e.target.value})}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    firstFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="ご担当者名"
                    type="text"
                    value={form.name}
                    onChange={e => setForm({ ...form, 'name': e.target.value})}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    firstFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="メールアドレス"
                    type="email"
                    value={form.email}
                    onChange={e => setForm({ ...form, 'email': e.target.value})}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    firstFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="メールアドレス（確認）"
                    type="email"
                    value={form.emailConfirm}
                    onChange={e => setForm({ ...form, 'emailConfirm': e.target.value})}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    firstFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons tech_mobile"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="電話番号"
                    type="text"
                    value={form.tel}
                    onChange={e => setForm({ ...form, 'tel': e.target.value})}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    onChange={e => setForm({ ...form, 'message': e.target.value})}
                    placeholder="お問い合わせ内容"
                    value={form.message}
                    rows="10"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  {isSending ? 
                    <Button
                      block
                      className="btn-round"
                      color="default"
                      onClick={() => sendMessage()}
                      size="lg"
                      disabled={true}
                    >
                      <Spinner color="secondary" /> 送信中
                    </Button>
                    :
                    
                    <Button
                      block
                      className="btn-round"
                      color="default"
                      onClick={() => sendMessage()}
                      size="lg"
                    >
                      送信
                    </Button>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default IndexPage;
