import React, { useEffect } from 'react';

export const NormalizeDatetime = (props) => {
    const date = props.date
    let normarizedDate = ''

    try{
        const splitedDate = date.split('T')
        normarizedDate = `${splitedDate[0]} ${splitedDate[1].substr(0,5)}`
    }catch(err){
        console.log('error', err)
    }

    return (
        <>
            {normarizedDate}
        </>
    )
};
