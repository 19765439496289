import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Breadcrumb,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Label,
  Container,
  ListGroupItem,
  ListGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Col
} from "reactstrap";

import { NormalizeDatetime } from '../../helpers/dateTransform'
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify";
import { updateContact } from '../../graphql/mutations'

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;


const initContact = {
  id: '',
  company: '',
  name: '',
  email: '',
  tel: '',
  message: '',
  memo: '',
  receivedAt: '',
}


const ContactTables = ( props ) => {
  // const history = useHistory()
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(initContact)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [memo, setMemo] = useState()

  const handleDeleteContact = async() =>{
    try{
      const inputData = {
        id: selectedContact.id,
        isDelete: 1,
      }
      const res = await API.graphql(graphqlOperation(updateContact, {input: inputData}))
      console.log('res', res)

    }catch(err){
      console.log('err', err)
    }
    props.setUpdated(selectedContact.id)
    setSelectedContact(initContact)
    setIsDeleteModalOpen(false)
  }

  const saveMemo = async() => {
    console.log('memo', memo)
    try{
      const inputData = {
        id: selectedContact.id,
        memo: memo,
      }
      const res = await API.graphql(graphqlOperation(updateContact, {input: inputData}))
      console.log('res', res)
      props.setUpdated(selectedContact.id)
      setSelectedContact(res.data.updateContact)

    }catch(err){
      console.log('err', err)
    }
  }

  return (
    <>
      <ToolkitProvider
        data={props.contacts}
        keyField="id"
        columns={[
          {
            dataField: "receivedAt",
            text: "-",
            sort: true,
            formatter: (cell, row) => {
              return(
                <>
                  <NormalizeDatetime date={row.receivedAt} />
                </>
              )
            },
          },
          {
            dataField: "company",
            text: "company",
            sort: true
          },
          {
            dataField: "name",
            text: "name",
            sort: true
          },
          {
            dataField: "message",
            text: "message",
            sort: true,
            formatter: (cell, row) => {
              return(
                <>
                {row.message ? <>{row.message.slice(0, 30)}…</> : null}
                </>
              )
            },
          },
          {
            dataField: "memo",
            text: "memo",
            sort: true,
            formatter: (cell, row) => {
              return(
                <>
                {row.memo ? <>{row.memo.slice(0, 20)}…</> : null}
                </>
              )
            },
          },
          {
            dataField: "",
            text: "",
            sort: false,
            formatter: (cell, row) => {
              return(
                <>
                {row.stockId===null ? null
                    : <Button color='secondary' size="sm" onClick={ () => {window.location.href=`/admin/stock/${row.stockId}`}}>在庫</Button>
                }   
                </>
              )
            },
          },
          {
            dataField: "",
            text: "",
            sort: false,
            formatter: (cell, row) => {
              return(
                <>
                  <Button color='info' size="sm" onClick={ () => {setSelectedContact(row); setIsDetailOpen(true)}}>詳細</Button>
                </>
              )
            },
          },
          {
            dataField: "",
            text: "",
            sort: false,
            formatter: (cell, row) => {
              return(
                <>
                  <Button color='danger' size="sm" onClick={ () => {setSelectedContact(row); setIsDeleteModalOpen(true)} }>削除</Button>
                </>
              )
            },
          },
        ]}
        search
      >
        {props => (
          <div className="py-4">
            <div
              id="datatable-basic_filter"
              className="dataTables_filter px-4 pb-1"
            >
              <label>
                <SearchBar
                  className="form-control-sm"
                  placeholder=""
                  {...props.searchProps}
                />
              </label>
            </div>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>

      {/* 詳細モーダル */}
      <Modal
        // className="modal-dialog-centered"
        // size="sm"
        className="modal-lg"
        modalClassName="modal-default"
        isOpen={isDetailOpen}
        toggle={() => setIsDetailOpen(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            {/* <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>card header title</small>
              </div>
            </CardHeader> */}
            <CardBody className="px-lg-5 py-lg-5">
              <ListGroup flush>
                <ListGroupItem
                  className="list-group-item-action flex-column align-items-start py-4 px-4"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                  tag="a"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <div>
                      <div className="d-flex w-100 align-items-center">
                        <h5 className="mb-1">{selectedContact.company}</h5>
                      </div>
                    </div>
                    <small><NormalizeDatetime date={selectedContact.receivedAt} /></small>
                  </div>
                  <h5 className="mt-3 mb-1">{selectedContact.name}</h5>
                  <div><small>{selectedContact.email}</small></div>
                  <small>{selectedContact.tel}</small>
                  <hr />
                  <p className="text-sm mb-0">
                    {selectedContact.message}
                  </p>
                  <hr />
                  <div className="textarea-container">
                    <Input
                      cols="80"
                      onChange={e => setMemo(e.target.value)}
                      placeholder="社内メモ"
                      defaultValue={selectedContact.memo}
                      rows="10"
                      type="textarea"
                      key={`${selectedContact.id}-memo`}
                    ></Input>
                    <div className="text-right">
                      <Button
                        className="my-4"
                        color="info"
                        type="button"
                        size="sm"
                        key={`${selectedContact.id}-memo-key`}
                        onClick={() => saveMemo()}
                      >
                        メモ保存
                      </Button>
                    </div>
                  </div>
                </ListGroupItem>
                
              </ListGroup>
              <div className="text-center">
                  <Button
                    className="my-4"
                    color="default"
                    type="button"
                    size="sm"
                    onClick={() => setIsDetailOpen(false)}
                  >
                    閉じる
                  </Button>
                </div>
            </CardBody>
          </Card>
        </div>
      </Modal>
      {/* 削除モーダル（論理削除） */}
      <Modal
          className="modal-dialog-centered"
          size='sm'
          isOpen={isDeleteModalOpen}
          toggle={() => setIsDeleteModalOpen(false)}
      >
          <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                  {selectedContact.name}さんの問い合わせを削除してよろしいでしょうか？
              </CardBody>
              <CardFooter className="text-center">
                  <Button
                      className="my-4"
                      color="default"
                      type="button"
                      size="sm"
                      onClick={() => setIsDeleteModalOpen(false)}
                  >
                      閉じる
                  </Button>
                  <Button
                      className="my-4"
                      color="info"
                      type="button"
                      size="sm"
                      onClick={() => handleDeleteContact() }
                  >
                      削除
                  </Button>
              </CardFooter>
          </Card>
          </div>
      </Modal>
    </>
  );
}

export default ContactTables;
