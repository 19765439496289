export const getStock = /* GraphQL */ `
  query GetStock($id: ID!) {
    getStock(id: $id) {
      id
      serial
      maker
      model
      hour
      year
      port
      youtubeUrl
      displayOrder
      price
      condition
    #   memo
      status
      category
      description
      image {
        bucket
        key
        region
      }
      imageSmall {
        bucket
        key
        region
      }
      imageMedium {
        bucket
        key
        region
      }
      imageLarge {
        bucket
        key
        region
      }
      isRental
      isPublish
      createdAt
      updatedAt
    }
  }
`;

export const listStocks = /* GraphQL */ `
  query ListStocks(
    $filter: ModelStockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        isRental
        isPublish
      }
      nextToken
    }
  }
`;