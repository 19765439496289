import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import { useParams } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Carousel, CarouselItem, CarouselIndicators,
  Spinner,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalFooter,
} from "reactstrap";

// responsible
import useMedia from 'use-media';

// core components
import CorpNavbar from "../../../components/Navbars/CorpNavbarStock.js";
import CorpStockDetailHeader from "../../../components/Headers/CorpStockDetailHeader.js";
import Footer from "../../../components/Footers/CorpFooter.js";

// SEO
import {Helmet} from "react-helmet";

// Amplify
import Amplify, { Auth, Storage, API, graphqlOperation } from "aws-amplify";
import { downloadStockImage, sendEmail} from '../../../graphql/queries'
import { getStock, listStocks } from './StockQueries'
import { createContact } from '../../../graphql/mutations'
Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"});
// console.log('auth', Auth.currentAuthenticatedUser)
Auth.currentAuthenticatedUser()
  .then(data => {Amplify.configure({aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"}); console.log('user-pools')})
  .catch(err => {Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"}); console.log('iam')});

const initStock = {
  category: '',
  condition: '',
  createdAt: '',
  description: null,
  displayOrder: null,
  hour: '',
  id: '',
  maker: '',
  memo: '',
  model: '',
  port: '',
  price: 0,
  serial: '',
  status: '',
  year: ''
}

const initForm = {
  company: '',
  name: '',
  email: '',
  emailConfirm: '',
  tel: '',
  message: '',
  titleHead: '',
}

const currentUTC = () => {
  let now = new Date();
  now.setTime(now.getTime() - 1000*60*60*9);// UCTへ変換
  let year = now.getYear(); // 年
  let month = now.getMonth() + 1; // 月
  let day = now.getDate(); // 日
  let hour = now.getHours(); // 時 (JST)
  let min = now.getMinutes(); // 分
  let sec = now.getSeconds(); // 秒
  
  if(year < 2000) { year += 1900; }
  
  // 数値が1桁の場合、頭に0を付けて2桁で表示
  // （例） 2020-12-11T15:30:00
  if(month < 10) { month = "0" + month; }
  if(day < 10) { day = "0" + day; }
  if(hour < 10) { hour = "0" + hour; }
  if(min < 10) { min = "0" + min; }
  if(sec < 10) { sec = "0" + sec; }

  return `${year}-${month}-${day}T${hour}:${min}:${sec}.000Z`
};

const StockDetailWithName = ( props ) => {
  const isWide = useMedia({minWidth: '1000px'});
  // URLパラメータ取得（model, serial）
  const { model, serial } = useParams();
  // console.log('serial', serial)
  const [stock, setStock] = React.useState(initStock);
  const [carouselImages, setCarouselImages] = React.useState([]);
  const [smallImages, setSmallImages] = React.useState([]);
  // Carousel --------
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  // download process
  const [isDownloading, setIsDownloading] = React.useState(false);
  // contact
  const [contactModal, setContactModal] = React.useState(false);
  const [form, setForm] = React.useState(initForm);
  const [isSending, setIsSending] = React.useState(false);
  // loading
  const [isLoaded, setIsLoaded] = React.useState(true);

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === carouselImages.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? carouselImages.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  // Carousel --------
  const convertYoutubeUrl = (url) => {
    const videoId = url.split('/').pop();
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  }
  
  React.useEffect(() => {
    // document.body.classList.add("about-us");
    // document.body.classList.add("sidebar-collapse");
    // document.documentElement.classList.remove("nav-open");
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    // return function cleanup() {
    //   document.body.classList.remove("about-us");
    //   document.body.classList.remove("sidebar-collapse");
    // };
  }, []);


  React.useEffect(() => {
    if(stock===initStock) fetchStock()
  }, []);

  const fetchStock = async() => {
    try{
      console.log('model', model)
      // modelとserialで一意となる
      let nextToken = null
      let stockList = []
      const resStockList = await API.graphql(graphqlOperation(listStocks, {filter: {model: {eq: model}, serial: {eq: serial}, isPublish: {eq: 1}}, limit: 1}))
      stockList = stockList.concat(resStockList.data.listStocks.items)
      nextToken = resStockList.data.listStocks.nextToken

      while(nextToken!==null && stockList.length===0){
        const resStockList = await API.graphql(graphqlOperation(listStocks, {filter: {model: {eq: model}, serial: {eq: serial}, isPublish: {eq: 1}}, limit:1, nextToken:nextToken}))
        stockList = stockList.concat(resStockList.data.listStocks.items)
        nextToken = resStockList.data.listStocks.nextToken
      }

       // 該当するデータがない場合は、在庫一覧へ
      if(stockList.length === 0) props.history.push("/stock/list");
      const stockId = stockList[0].id



      const res = await API.graphql(graphqlOperation(getStock, {id: stockId}))
      const resStock = res.data.getStock
      setStock(resStock)

      let carouselImageList = []
      for(const image of resStock.image){
        const imageUrl = await Storage.get(image.key)
        carouselImageList.push(imageUrl)  
      }
      setCarouselImages(carouselImageList)

      let smallImageList = []
      for(const image of resStock.imageSmall){
        const imageUrl = await Storage.get(image.key)
        smallImageList.push(imageUrl)
      }
      setSmallImages(smallImageList)
      setIsLoaded(false)

    }catch(err){
      console.log('error', err)
      // todo back to list

    }
  }

  const handleDownload = async() =>{
    console.log('downloading...')
    setIsDownloading(true)
    let downloadUrl = ''
    try{
      // API
      const res = await API.graphql(graphqlOperation(downloadStockImage, {stockId: stock.id}))
      // console.log('res', res)
      const data = JSON.parse(res.data.downloadStockImage)
      downloadUrl = data.data.downloadUrl
      // console.log('downloadUrl', downloadUrl)
      window.location.href = downloadUrl

    }catch(err){
      console.log('error', err)
    } 

    setIsDownloading(false)
  }

  const sendMessage = async() =>{
    // console.log('send message', form)

    try{
      // バリデーション
      let valMessage = ''
      if(form.company==='' || form.name==='' || form.email==='' || form.emailConfirm==='' || form.tel==='' || form.message==='' ){
        window.alert('全て入力してください');
        return
      }
      if(form.email !== form.emailConfirm){
        window.alert('メールアドレスが一致しません');
        return
      }
      // 送信
      setIsSending(true)
      // メール送信用API（gmail暫定版）
      try{
        const res = await API.graphql(graphqlOperation(sendEmail, {data: JSON.stringify(form)}))
        // console.log('res', res)
        const resData = JSON.parse(res.data.sendEmail)
        // メール送信は失敗しても、DB保存されればOKとする（暫定対応）
        // if(resData.statusCode !== 200){
        //   window.alert('送信に失敗しました。恐れ入りますが、もう一度やり直してください。');
        //   return
        // }
      }catch(err){
        console.log('err', err)
      }
      
      try{

        const messageText = `\
          [${stock.model}#${stock.serial}]\
          ${form.message} 
        `
        const inputContact = {
          company: form.company,
          name: form.name,
          email: form.email,
          tel: form.tel,
          message: messageText,
          receivedAt: currentUTC(),
          isDelete: 0,
          stockId: stock.id
        }
        console.log('inputContact', inputContact)
        const resContact = await API.graphql(graphqlOperation(createContact, {input: inputContact}))
        // console.log('resContact', resContact)
        // [成功]メッセージ受付ダイアログ
        window.alert('お問い合わせありがとうございました。担当よりご連絡致します。');

      }catch(err){
        window.alert(`送信に失敗しました。
恐れ入りますが、 sales@matscorp.com までお問い合わせください。
      `);
      return
      }

    }catch(err){
      console.log('error', err)
      //　エラーダイアログ
      window.alert(`送信に失敗しました。
恐れ入りますが、 sales@matscorp.com までお問い合わせください。
      `);
      return
    }
    setIsSending(false)
    setForm(initForm)
    setContactModal(false)
  }

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`${stock.model} 中古`}</title>
          <meta name="description" content={`${stock.model} 中古`} />
          <meta name="keywords" content= {`${stock.category},${stock.maker},${stock.model},松井商事株式会社,中古,中古建機,関東,茨城,かすみがうら,ショベル,ミニショベル,ブルドーザー,ホイールローダー`}/>
      </Helmet>
      <CorpNavbar />
      <div className="wrapper">
        {/* <CorpStockDetailHeader /> */}
        {/* <div className="section"> */}
            <div className="blogs-4" id="blogs-4">
            {isLoaded ? 
              <Container>
                <div className="text-center" style={{marginTop:"100px"}}>
                  <Spinner animation="border" variant="primary" />
                </div>
              </Container>
            :
              <Container>
                <Row>
                  在庫詳細
                </Row>
                <Row>
                  <a href="/stock/list"><b>在庫一覧に戻る</b></a>
                </Row>
                <Row>
                  {/* <Col className="offset-md-1" md="8"> */}
                    <Col className="ml-auto mr-auto" md="8">
                      <h2 className="title">{`${stock.maker}  ${stock.model}`}</h2>
                      <br></br>
                      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                        <CarouselIndicators
                          items={carouselImages}
                          activeIndex={activeIndex}
                          onClickHandler={goToIndex}
                        />
                        {carouselImages.map((image, index) => {
                          return (
                            <CarouselItem
                              onExiting={onExiting}
                              onExited={onExited}
                              key={index}
                            >
                              <img src={image} />
                              <div className="carousel-caption d-none d-md-block">
                                {/* <h5>{item.caption}</h5> */}
                              </div>
                            </CarouselItem>
                          )
                        }
                        )}                        
                        <a
                          className="carousel-control-prev"
                          data-slide="prev"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            previous();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-left"></i>
                        </a>
                        <a
                          className="carousel-control-next"
                          data-slide="next"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            next();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </a>
                      </Carousel>
                      <footer className="footer footer-big">
                        <Container>
                        <div className="content"></div>
                        <div className="gallery-feed">
                          {smallImages.map((image, index) => {
                            return (
                              <>
                              {isWide ?
                                <img
                                  alt="..."
                                  className="img img-raised rounded"
                                  src={image}
                                  onClick={() => setActiveIndex(index)}
                                  key={`small-${index}`}
                                  style={{ height: '87.297pt'}}
                                ></img>
                              :
                                <img
                                  alt="..."
                                  className="img img-raised rounded"
                                  src={image}
                                  onClick={() => setActiveIndex(index)}
                                  key={`small-${index}`}
                                  style={{height: '51.781pt'}}
                                ></img>
                              }
                              </>
                            )}
                          )}
                        </div>
                        </Container>
                      </footer>
                    </Col>
                    <Col md="3">
                    <h2 className="title"></h2>
                    <br></br>
                    <br/>
                      <Card className="card-pricing text-left">
                        {stock.youtubeUrl==="" ? null
                          : stock.youtubeUrl===null ? null
                              : <iframe src={convertYoutubeUrl(stock.youtubeUrl)} title="311DRR #AKW01111" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        }
                        <CardBody>
                          <div><b>Category: </b> {stock.category}</div>
                          <div><b>Maker: </b> {stock.maker}</div>
                          <div><b>Model: </b> {stock.model}</div>
                          <div><b>Serial: </b> {stock.serial}</div>
                          <div><b>Year: </b> {stock.year}</div>
                          <div><b>Hour: </b> {stock.hour}</div>
                          <div><b>Place: </b> {stock.port}</div>
                          <div><b>Remark: </b> {stock.condition}</div>
                          <br />
                          <div><b>Price: </b> 
                            {stock.price===-1 ? <span className="price price text-danger"><b>ASK</b></span>
                              : stock.status==='Hold'? <span className="price price text-default">HOLD</span>
                                : stock.status==='Sold'? <span className="price price text-default">SOLD</span>
                                  : <span className="price text-danger"><b>¥{stock.price.toLocaleString()}</b></span>
                            }
                            {stock.isRental===1 ? 
                                <>
                                <br />
                                    <div className="card-description text-left">レンタル可</div> 
                                </>
                            : null }
                          </div>
                        </CardBody>
                      </Card>
                      <div align='center'>
                      {isDownloading ? 
                        <Button className="btn-round" color="info" type="button" size="sm">
                          <Spinner color="secondary" /> downloading
                        </Button>   
                      :
                        <Button
                          className="btn-round"
                          color="info"
                          href="#pablo"
                          onClick={() => handleDownload()}
                        >
                          画像一括ダウンロード
                        </Button>     
                      }
                         
                      </div>
                      <div align="center">
                        <Button
                          className="btn-round"
                          color="default"
                          href="#pablo"
                          onClick={() => {setForm({ ...form, 'titleHead': `[${stock.maker}  ${stock.model}]`}); setContactModal(true);}}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;お問い合わせ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Button>  
                      </div>
                    </Col>
                  {/* </Col> */}
                </Row>
              </Container>
            }
            </div>
        <Footer />
        <Modal
          className="modal-lg"
          modalClassName="modal-default"
          isOpen={contactModal}
          toggle={() => setContactModal(false)}
        >
          <Card
            className="card-login card-plain"
            // data-background-color=""
          >
            <div className="modal-body">
              <InputGroup>
                <Input
                  type="text"
                  value={`${stock.model}#${stock.serial}`}
                  disabled={true}
                ></Input>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons business_badge"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="企業名"
                  type="text"
                  value={form.company}
                  onChange={e => setForm({ ...form, 'company': e.target.value})}
                ></Input>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons users_circle-08"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="ご担当者名"
                  type="text"
                  value={form.name}
                  onChange={e => setForm({ ...form, 'name': e.target.value})}
                ></Input>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons ui-1_email-85"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="メールアドレス"
                  type="email"
                  value={form.email}
                  onChange={e => setForm({ ...form, 'email': e.target.value})}
                ></Input>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons ui-1_email-85"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="メールアドレス（確認）"
                  type="email"
                  value={form.emailConfirm}
                  onChange={e => setForm({ ...form, 'emailConfirm': e.target.value})}
                ></Input>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons tech_mobile"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="電話番号"
                  type="text"
                  value={form.tel}
                  onChange={e => setForm({ ...form, 'tel': e.target.value})}
                ></Input>
              </InputGroup>
              <div className="textarea-container">
                <Input
                  cols="80"
                  onChange={e => setForm({ ...form, 'message': e.target.value})}
                  placeholder="お問い合わせ内容"
                  value={form.message}
                  rows="10"
                  type="textarea"
                ></Input>
              </div>
              <div className="send-button">
                {isSending ? 
                  <Button
                    block
                    className="btn-round"
                    color="default"
                    size="lg"
                    disabled={true}
                  >
                    <Spinner color="secondary" /> 送信中
                  </Button>
                  :
                  
                  <Button
                    block
                    className="btn-round"
                    color="default"
                    onClick={() => sendMessage()}
                    size="lg"
                  >
                    送信
                  </Button>
                }
              </div>
            </div>
          </Card>
        </Modal>
      </div>
    </>
  );
}

export default StockDetailWithName;
