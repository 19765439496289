import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";


const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;


const NewsTable = ( props ) => {
  const history = useHistory()

  return (
    <>
      <ToolkitProvider
        data={props.news}
        keyField="id"
        columns={[
          {
            dataField: "publishAt",
            text: "日付",
            sort: true
          },
          {
            dataField: "message",
            text: "お知らせ",
            sort: false,
            formatter: (cell, row) => {
              return(
                <>
                  {`${row.message.slice(0, 20)}…`}
                </>
              )
            },
          },
          // {
          //   dataField: "isPublish",
          //   text: "Public",
          //   sort: true,
          //   formatter: (cell, row) => {
          //     return(
          //       <>
          //         {row.isPublish===1 ? <Badge color="default" className="mr-1">公開</Badge>
          //           : <Badge color="secondary" className="mr-1">非公開</Badge>
          //         }
          //       </>
          //     )
          //   },
          // },
          {
            dataField: "",
            text: "",
            sort: false,
            formatter: (cell, row) => {
              return(
                <>
                  {/* <i className={`${row.icon}`} /> */}
                  <Button color='info' size="sm" onClick={ () => { 
                    props.setSelectedNews(row.id); 
                    props.setForm({publishAt: row.publishAt , message: row.message, messageEn: row.messageEn });
                    props.setIsModalOpen(true) }}
                  >
                    編集
                  </Button>
                </>
              )
            },
          },
          {
            dataField: "",
            text: "",
            sort: false,
            formatter: (cell, row) => {
              return(
                <>
                  <Button color='danger' size="sm" onClick={ () => {
                    props.setSelectedNews(row.id); 
                    props.setForm({publishAt: row.publishAt , message: row.message,  messageEn: row.messageEn });
                    props.setIsDeleteModalOpen(true);
                    }}
                  >
                    削除
                  </Button>
                </>
              )
            },
          },
        ]}
        search
      >
        {props => (
          <div className="py-4">
            <div
              id="datatable-basic_filter"
              className="dataTables_filter px-4 pb-1"
            >
              <label>
                <SearchBar
                  className="form-control-sm"
                  placeholder=""
                  {...props.searchProps}
                />
              </label>
            </div>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
}

export default NewsTable;
