import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    Spinner
  } from "reactstrap";

import StockTable from "../../../admin_components/tables/StockTable";

// GraphQL API
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify";
import { queryStockGsiOrderedList } from '../../../graphql/queries'
import { deleteStock } from '../../../graphql/mutations'

const initSelectedStock = {
  id: '',
  serial: '',
  maker: '',
  model: '',
  hour: '',
  year: '',
  port: '',
  displayOrder: null,
  price: null,
  condition: '',
  memo: '',
  status: '',
  category: '',
  description: '',
  image:'',
  imageSmall:'',
  imageMedium: '',
  imageLarge:'',
  isPublish: null
}


const StockPage = (props)=>{
    const [stocks, setStocks] = useState([])
    const [selectedStock, setSelectedStock] = useState(initSelectedStock)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    useEffect(() => {
        fetchStocks(); 

    }, []);

    const fetchStocks = async() =>{
        const publishList = await getOrderedStock(1)
        const nonPublichList = await getOrderedStock(0)
        const stockList = publishList.concat(nonPublichList)
        setStocks(stockList)
    }

    const getOrderedStock = async( isPublish ) => {
        let stockList = []
        try{
            let nextToken = null
            const res = await API.graphql(graphqlOperation(queryStockGsiOrderedList, {isPublish: isPublish, sortDirection:"ASC"}))
            const items = res.data.queryStockGsiOrderedList.items
            for(const item of items){
              let stockItem = item
              try{
                const photoUrl = await Storage.get(item.imageMedium[0].key)
                stockItem['photoUrl'] = photoUrl
              }catch(err){
                stockItem['photoUrl'] = null
              }
              stockList.push(stockItem) 
            }
            nextToken = res.data.queryStockGsiOrderedList.nextToken
    
            while(nextToken!==null){
              const res = await API.graphql(graphqlOperation(queryStockGsiOrderedList, {isPublish: isPublish, sortDirection:"ASC", nextToken: nextToken}))
              const items = res.data.queryStockGsiOrderedList.items
              for(const item of items){
                let stockItem = item
                try{
                  const photoUrl = await Storage.get(item.imageMedium[0].key)
                  stockItem['photoUrl'] = photoUrl
                }catch(err){
                  stockItem['photoUrl'] = null
                }
                stockList.push(stockItem) 
              }
              nextToken = res.data.queryStockGsiOrderedList.nextToken
            }
    
            console.log('stockList', stockList)
    
          }catch(err){
            console.log('error', err)
          }
          return stockList
    }

    const handleDeleteStock = async() =>{
      console.log('handleDeleteStock', selectedStock)
      setIsDeleting(true)

      try{
        // DBから削除
        const inputData = {
          id: selectedStock.id
        }
        const res = await API.graphql(graphqlOperation(deleteStock, {input: inputData}))
        console.log('delete_res', res)

        // アップロードファイルを削除
        if(selectedStock.image){
          await deleteS3Objects()
        }

      }catch(err){
        console.log('error', err)
      }

      // 後処理
      setSelectedStock(initSelectedStock)
      await fetchStocks()
      setIsDeleting(false)
      setIsDeleteModalOpen(false)
    }

    const deleteS3Objects = async() =>{
      // todo: delete s3 data
      try{
        for(const [index, image] of selectedStock.imageSmall.entries()){
          let result = await Storage.remove(image.key)
          console.log('result', result)
        }
        for(const [index, image] of selectedStock.imageMedium.entries()){
          let result = await Storage.remove(image.key)
          console.log('result', result)
        }
        for(const [index, image] of selectedStock.imageLarge.entries()){
          let result = await Storage.remove(image.key)
          console.log('result', result)
        }
        for(const [index, image] of selectedStock.image.entries()){
          let result = await Storage.remove(image.key)
          console.log('result', result)
        }
        return 'ok'
      }catch(err){
        console.log('error', err)
        return 'error'
      }
    }



    return (
        <>
            {/* header */}
            <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                <Container fluid>
                <div className="header-body">
                    <Row className="align-items-center py-4">
                    <Col lg="6" xs="7">
                        <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                            在庫管理
                        </h6>{" "}
                    </Col>
                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                        <Button color="default" type="button"　size="sm" onClick={() => {props.history.push(`/admin/stock/new`)}}>
                            新規作成
                        </Button>
                    </Col>
                    </Row>
                </div>
                </Container>
            </div>

            {/* search & filter */}
            <Container className="mt--6" fluid>
                <Row>
                    <Col xs="12">
                        <Card className="mb-4">
                        <CardBody>
                            <StockTable stocks={stocks} setSelectedStock={setSelectedStock} setIsDeleteModalOpen={setIsDeleteModalOpen} />
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* 削除確認モーダル */}
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(false)}
            >
                <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        {`${selectedStock.maker} ${selectedStock.model} を削除してよろしいでしょうか？`}
                    </CardBody>
                    <CardFooter className="text-center">
                        <Button
                            className="my-4"
                            color="default"
                            type="button"
                            size="sm"
                            onClick={() => setIsDeleteModalOpen(false)}
                        >
                            閉じる
                        </Button>
                        {isDeleting 
                          ? 
                          <Button className="my-4" color="danger" type="button" size="sm">
                            <Spinner color="secondary" size='sm' /> 削除
                          </Button> 
                          :
                          <Button
                            className="my-4"
                            color="danger"
                            type="button"
                            size="sm"
                            onClick={() => handleDeleteStock()}
                          >
                              削除
                          </Button>
                        }
                    </CardFooter>
                </Card>
                </div>
            </Modal>
        </>
    )
}

export default StockPage;