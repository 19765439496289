// import Alternative from "views/pages/dashboards/Alternative.js";
// import Buttons from "views/pages/components/Buttons.js";
// import Calendar from "views/pages/Calendar.js";
// import Cards from "views/pages/components/Cards.js";
// import Charts from "views/pages/Charts.js";
// import Components from "views/pages/forms/Components.js";
// import Dashboard from "views/pages/dashboards/Dashboard.js";
// import Elements from "views/pages/forms/Elements.js";
// import Google from "views/pages/maps/Google.js";
// import Grid from "views/pages/components/Grid.js";
// import Icons from "views/pages/components/Icons.js";
// import Lock from "views/pages/examples/Lock.js";
// import Login from "views/pages/examples/Login.js";
// import Notifications from "views/pages/components/Notifications.js";
// import Pricing from "views/pages/examples/Pricing.js";
// import Profile from "views/pages/examples/Profile.js";
// import ReactBSTables from "views/pages/tables/ReactBSTables.js";
// import Register from "views/pages/examples/Register.js";
// import RTLSupport from "views/pages/examples/RTLSupport.js";
// import Sortable from "views/pages/tables/Sortable.js";
// import Timeline from "views/pages/examples/Timeline.js";
// import Typography from "views/pages/components/Typography.js";
// import Validation from "views/pages/forms/Validation.js";
// import Vector from "views/pages/maps/Vector.js";
// import Widgets from "views/pages/Widgets.js";

// samples
import Tables from "./pages/admin/sample/table/Tables";
import NewsPage from "./pages/admin/news/NewsPage";
import StockPage from "./pages/admin/stock/StockPage";
import ContactPage from "./pages/admin/contact/ContactPage";
import Signout from "./pages/admin/auth/Signout";

// import Sortable from "./pages/admin/sample/table/Sortable";

// menu
// todo delete
// import HomePage from './pages/admin_pages/home/HomePage'
// import UserPage from './pages/admin_pages/user/UserPage'
// import RequestPage from './pages/admin_pages/request/RequestPage'


const routes = [
  {
    path: "/stock",
    name: "在庫",
    icon: "ni ni-box-2 text-primary",
    component: StockPage,
    layout: "/admin",
  },
  {
    path: "/contact",
    name: "問い合わせ",
    icon: "ni ni-email-83 text-info",
    component: ContactPage,
    layout: "/admin",
  },
  {
    path: "/news",
    name: "お知らせ",
    icon: "ni ni-bell-55 text-warning",
    component: NewsPage,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-curved-next text-default",
    component: Signout,
    layout: "/admin",
  },
]

export default routes;
