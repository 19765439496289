import React, { useState } from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardLink,
} from "reactstrap";



const StockCard = ( props ) => {

    const [stock, setStock] = useState(props.stock)

    return (
        <Card className="card-product">
            <div className="card-image">
                {/* <a href={`/stock/${stock.model}/${stock.serial}`}> */}
                <a href={`/stock/${stock.id}`}>
                <img
                    alt="..."
                    className="img rounded"
                    src={stock.photoUrl}                          
                ></img>
                </a>
            </div>
            <CardBody>
                <h6 className="category text-danger">{stock.category}</h6>
                <CardTitle tag="h2" style={
                        stock.youtubeUrl==="" ? {}
                        : stock.youtubeUrl===null ? {}
                            : { display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', justifyContent: 'space-between'}
                    }
                >
                {/* <CardTitle tag="h2" style={{ display: 'flex' }}> */}
                {/* <CardTitle tag="h2"> */}
                    <CardLink
                        href={`/stock/${stock.model}/${stock.serial}`}
                        // href={`/stock/${stock.id}`}
                    >
                        {stock.model}
                    </CardLink>
                    {stock.youtubeUrl==="" ? null
                        : stock.youtubeUrl===null ? null
                            : 
                                <a href={stock.youtubeUrl} target="_blank" style={{ paddingRight: 10 }}>
                                    <img
                                        alt="..."
                                        src={require("assets/img/sns/app_icon_youtube.png")}
                                        // style={{paddingLeft: 20}}
                                    /> 
                                </a>
                    }
                </CardTitle>
                <div className="card-description text-left">
                <b>Maker</b>: {stock.maker}<br/>
                <b>Model</b>: {stock.model}<br/>
                <b>Serial</b>: &nbsp;{stock.serial}<br/>
                <b>Year</b>:&nbsp;&nbsp;&nbsp;&nbsp;{stock.year}<br/>
                <b>Hour</b>:&nbsp;&nbsp;&nbsp;&nbsp;{stock.hour}<br/>
                <b>Place</b>:&nbsp;&nbsp;&nbsp;{stock.port}<br/>
                </div>

                <CardFooter>
                <div className="price-container">
                    {stock.price===-1 ? <span className="price price text-danger"><b>ASK</b></span>
                        : stock.status==='Hold'? <span className="price price text-default">HOLD</span>
                            : stock.status==='Sold'? <span className="price price text-default">SOLD</span>
                                : <span className="price text-danger"><b>¥{stock.price.toLocaleString()}</b></span>
                    }
                </div>
                {stock.isRental===1 ? 
                    <>
                    <br />
                        <div className="card-description text-left">レンタル可</div> 
                    </>
                : null }
                </CardFooter>
            </CardBody>
        </Card>
    )
}
export default StockCard;