import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AdminNavbar from "../admin_components/navbars/AdminNavbar.js";
import AdminFooter from "../admin_components/footers/AdminFooter.js";
import Sidebar from "../admin_components/sidebar/AdminSidebar.js";
import routes from "../admin-routes.js";

// added Route
import StockDetailPage from "../pages/admin/stock/StockDetailPage";


// amplify 
import { Amplify, Auth } from "aws-amplify";

function Admin(props) {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const history = useHistory();
  // adminユーザ関連
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [adminEmail, setAdminEmail] = React.useState(null);
  // ログイン
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  React.useEffect(() => {
    console.log('effect...')
    checkIsAdmin();
  }, [history])
  
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  // ログインユーザがadminグループに所属しているかチェック
  const checkIsAdmin = async () => {
    try{
      const loginUser = await Auth.currentAuthenticatedUser();
      console.log(loginUser)
      const cognitoGroups = loginUser.signInUserSession.accessToken.payload['cognito:groups']
      console.log('cognitoGroups', cognitoGroups)
      if(cognitoGroups.includes('admin')){
        console.log('admin: ok');
        Amplify.configure({aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"});
        setAdminEmail(loginUser.attributes.email);
        setIsAdmin(true);
      }else{
        setIsAdmin(false)
      }
    }catch(err){
      console.log('error', err);
      setIsAdmin(false);
    }
  }

  // ログイン
  const handleLogin = async (e) => {
    try{
      const signInInfo = await Auth.signIn({
        username: email,
        password: password,
      });
      checkIsAdmin();

    }catch(err){
      console.log('error', err);
    }
  }

  const renderContents = () => {
    if (isAdmin) {
      return (
        <>
          <Sidebar
            routes={routes}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            logo={{
              innerLink: "/admin",
              imgSrc: require("admin_assets/img/brand/argon-react.png").default,
              imgAlt: "Admin",
            }}
          />
          <div className="main-content" ref={mainContentRef}>
            {/* <AdminNavbar
              theme={getNavbarTheme()}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              brandText={getBrandText(location.pathname)}
            /> */}
            <Switch>
              <Route
                  path="/admin/stock/:stockId"
                  exact
                  render={(props) => <StockDetailPage {...props} />}
                />
              {getRoutes(routes)}
                
              <Redirect from="*" to="/admin/home" />
            </Switch>
            <AdminFooter />
          </div>
          {/* <AmplifySignOut /> */}
          {sidenavOpen ? (
            <div className="backdrop d-xl-none" onClick={toggleSidenav} />
          ) : null}
        </>
      )
    }else{
      return (
        <>
        <div className="main-content" ref={mainContentRef}>
          <AdminNavbar
            theme={getNavbarTheme()}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(location.pathname)}
            adminEmail={adminEmail}
          />
          <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
              <Container fluid>
              <div className="header-body">
                  <Row className="align-items-center py-4">
                  <Col lg="6" xs="7">
                  </Col>
                  </Row>
              </div>
              </Container>
          </div>

          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>管理ユーザ ログイン</small>
                      </div>
                      <Form role="form">
                        <FormGroup
                          className={classnames("mb-3", {
                            focused: focusedEmail,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              onFocus={() => setfocusedEmail(true)}
                              onBlur={() => setfocusedEmail(true)}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: focusedPassword,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              onFocus={() => setfocusedPassword(true)}
                              onBlur={() => setfocusedPassword(true)}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="text-center">
                          <Button className="my-4" color="info" type="button" onClick={()=>handleLogin()}>
                            Login
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          {sidenavOpen ? (
            <div className="backdrop d-xl-none" onClick={toggleSidenav} />
          ) : null}
        </>
      )
    }
  }

    return (
      <>
       {renderContents()}
      </>
    )
}
export default Admin;
// export default withAuthenticator(Admin);