import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardLink,
  CardTitle,
  Collapse,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import ProductPageHeader from "components/Headers/ProductPageHeader.js";
import FooterSocial from "components/Footers/FooterSocial.js";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import DemoListHeader from "components/Headers/DemoListHeader.js";
import Footer from "components/Footers/DemoFooter.js";

// i18n
import '../i18n';
import { useTranslation } from 'react-i18next';

// Amplify
import Amplify, { Auth, Storage, API, graphqlOperation } from "aws-amplify";
import { queryStockGsiOrderedList } from '../graphql/queries'
Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"});
console.log('auth', Auth.currentAuthenticatedUser)
Auth.currentAuthenticatedUser()
  .then(data => {Amplify.configure({aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"}); console.log('user-pools')})
  .catch(err => {Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"}); console.log('iam')});


const items = [
  {
    src: require("assets/img/pp-1.jpg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/pp-2.jpg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/pp-3.jpg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/pp-4.jpg"),
    altText: "",
    caption: "",
  },
];

function DemoList() {
  // carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const [ t, i18n ] = useTranslation();

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  // select states and functions
  const [colorSelect, setColorSelect] = React.useState(null);
  const [sizeSelect, setSizeSelect] = React.useState(null);
  React.useEffect(() => {
    document.body.classList.add("product-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("product-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);


  React.useEffect(() => {
    fetchStocks(); 

  }, []);

  const fetchStocks = async() =>{
      try{
          // todo: nextToken対応, sort対応
          const res = await API.graphql(graphqlOperation(queryStockGsiOrderedList, {isPublish:1, sortDirection:"DESC"}))
          console.log('res', res)

      }catch(err){
          console.log('error', err)

      }
  }


  return (
    <>
      <DemoNavbar />
      <div className="wrapper">
        <DemoListHeader />

        {/* <div className="section related-products" data-background-color="black"> */}
        <div className="section related-products" data-background-color="">
          <Container>
            {/* <h3 className="title text-center">
              You may also be interested in:
            </h3> */}
            <Row>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="/demo-detail">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo01.png")}
                        
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">{t('Bulldozer')}</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="/demo-detail"
                        // onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo02.png")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo03.png")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo04.png")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo05.png")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo06.jpg")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo07.jpg")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo01.png")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo01.png")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo01.png")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo01.png")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/demo/demo01.png")}
                        width="225" height="145"
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">ブルドーザー</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        CAT D10R
                      </CardLink>
                    </CardTitle>
                    <div className="card-description text-left">
                      メーカー: CAT<br/>
                      型式: D10R<br/>
                      号機: 3KR01254<br/>
                      年式: 1999YR<br/>
                      時間: 22,420HR<br/>
                      在庫地: 成田<br/>
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">9,500,000円</span>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default DemoList;
