import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Breadcrumb,
    Button,
    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";


import { Auth } from "aws-amplify";

const Signout = (props)=>{

    const history = useHistory();
    
    useEffect(() => {
    }, []);

    const handleSignout = async() =>{
        try {
                await Auth.signOut();
                history.push('/admin');
            }
            catch(err){
            }
      }

    

    return (
        <>
            <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                <Container fluid>
                <div className="header-body">
                    <Row className="align-items-center py-4">
                    <Col lg="6" xs="7">
                        <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                            ログアウト
                        </h6>{" "}
                    </Col>
                    </Row>
                </div>
                </Container>
            </div>

            {/* search & filter */}
            <Container className="mt--6" fluid>
                <Row>
                    <Col xs="12">
                        <Card className="mb-4">
                        <CardBody>
                        <Button color="default" type="button"　size="sm" onClick={() => handleSignout()}>
                            SignOut
                        </Button>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Signout;