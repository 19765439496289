import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Button,
    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter
  } from "reactstrap";

import ContactTable from "../../../admin_components/tables/ContactTable";

// GraphQL API
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify";
import { queryContactGsiOrderedList } from '../../../graphql/queries'



const ContactPage = (props)=>{
    const [contacts, setContacts] = useState([])
    const [updated, setUpdated] = useState('')

    useEffect(() => {
        fetchContacts(); 

    }, [updated]);

    const fetchContacts = async() =>{
        try{
            let contactList = []
            let nextToken = null
            const res = await API.graphql(graphqlOperation(queryContactGsiOrderedList, {isDelete:0, sortDirection:"DESC"}))
            console.log('res', res)
            nextToken = res.data.queryContactGsiOrderedList.nextToken
            contactList = contactList.concat(res.data.queryContactGsiOrderedList.items)
            while(nextToken!==null){
                const res = await API.graphql(graphqlOperation(queryContactGsiOrderedList, {isDelete:0, sortDirection:"DESC", nextToken: nextToken}))
                console.log('res', res)
                nextToken = res.data.queryContactGsiOrderedList.nextToken
                contactList = contactList.concat(res.data.queryContactGsiOrderedList.items)
            }
            setContacts(contactList)

        }catch(err){
            console.log('error', err)

        }
    }

    return (
        <>
            {/* header */}
            <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                <Container fluid>
                <div className="header-body">
                    <Row className="align-items-center py-4">
                    <Col lg="6" xs="7">
                        <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                            問い合わせ
                        </h6>{" "}
                    </Col>
                    </Row>
                </div>
                </Container>
            </div>

            {/* search & filter */}
            <Container className="mt--6" fluid>
                <Row>
                    <Col xs="12">
                        <Card className="mb-4">
                        <CardBody>
                            <ContactTable contacts={contacts} setUpdated={setUpdated} />
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ContactPage;