import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardLink,
  CardHeader,
  UncontrolledTooltip,
  ListGroupItem,
  ListGroup,
  Label,
  Carousel, CarouselItem, CarouselIndicators
} from "reactstrap";


// core components
// import DemoNavbar from "components/Navbars/DemoDetailNavbar.js";
import DemoHeader from "components/Headers/DemoHeader.js";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footers/DemoFooter.js";

const items = [
  {
    src: require("assets/img/demo/demo01.png"),
    altText: "",
    caption: ""
  },
  {
    src: require("assets/img/demo/demo04.png"),
    altText: "",
    caption: ""
  }
];

function DemoDetail() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [firstNameFocus, setFirstNameFocus] = React.useState(false);
  const [lastNameFocus, setLastNameFocus] = React.useState(false);

  // Carousel --------
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  // Carousel --------
  
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DemoNavbar />
      {/* <div className="wrapper"> */}
        {/* <div className="section"> */}
            <div className="blogs-4" id="blogs-4">
              <Container>
                <Row>
                  <Card className="card-profile" data-background-color="black">
                    <CardBody>
                      <h6 className="card-category">松井商事株式会社</h6>
                      <CardTitle tag="h4">お問い合わせ</CardTitle>
                      <p className="card-description">
                      Tel：<b>03-5734-1858</b>  (Fax：03-5734-1858)<br />
                      Email：sales@matsucorp.com
                      </p>
                      {/* <Button
                        className="btn-round"
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        Call
                      </Button> */}
                    </CardBody>
                  </Card>
                </Row>
                <Row>
                  <a href="demo-list">在庫一覧</a>
                </Row>
                <Row>
                  {/* <Col className="offset-md-1" md="8"> */}
                    <Col className="ml-auto mr-auto" md="8">
                      <h2 className="title">CAT D10R 3KR01254</h2>
                      <br></br>
                      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                        <CarouselIndicators
                          items={items}
                          activeIndex={activeIndex}
                          onClickHandler={goToIndex}
                        />
                        {items.map(item => {
                          return (
                            <CarouselItem
                              onExiting={onExiting}
                              onExited={onExited}
                              key={item.src}
                            >
                              <img src={item.src} alt={item.altText} />
                              <div className="carousel-caption d-none d-md-block">
                                <h5>{item.caption}</h5>
                              </div>
                            </CarouselItem>
                          );
                        })}
                        <a
                          className="carousel-control-prev"
                          data-slide="prev"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            previous();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-left"></i>
                        </a>
                        <a
                          className="carousel-control-next"
                          data-slide="next"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            next();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </a>
                      </Carousel>
                      <footer className="footer footer-big footer-white">
                        <Container>
                        <div className="content"></div>
                        <div className="gallery-feed">
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            src={require("assets/img/demo/demo01.png")}
                            onClick={() => setActiveIndex(0)}
                          ></img>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            src={require("assets/img/demo/demo01.png")}
                          ></img>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            src={require("assets/img/demo/demo04.png")}
                          ></img>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            src={require("assets/img/demo/demo04.png")}
                          ></img>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            src={require("assets/img/demo/demo04.png")}
                          ></img>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            src={require("assets/img/demo/demo04.png")}
                          ></img>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            src={require("assets/img/demo/demo01.png")}
                          ></img>
                          <img
                            alt="..."
                            className="img img-raised rounded"
                            src={require("assets/img/demo/demo01.png")}
                          ></img>
                        </div>
                        </Container>
                      </footer>
                      <div align='left'>
                          <Button
                            className="btn-round"
                            color="info"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            画像一括ダウンロード
                          </Button>
                         </div>
                    </Col>
                    <Col md="3">
                    <h3 className="title">Stock No. xxxxxx</h3>
                    <br/>
                      <Card className="card-pricing text-left">
                        <CardBody>
                          <CardTitle tag="h3">
                            <small>title ....</small>
                          </CardTitle>
                          <div align="left">
                            <ul>
                              <li>
                                <b>Model</b> ABCDEF
                              </li>
                              <li>
                                <b>Serial No.</b> 123456
                              </li>
                              <li>
                                <b>Year</b> 2021
                              </li>
                              <li>
                                <b>Meter</b> 123
                              </li>
                              <li>
                                <b>Delivery</b> XXX 
                              </li>
                              <li>
                                <b>Price</b> 200,000
                              </li>
                            </ul>
                          </div>
                          
                        </CardBody>
                      </Card>
                      <Card className="card-pricing text-left">
                        <CardBody>
                          <CardTitle tag="h3">
                            <small><b>Remark</b></small>
                          </CardTitle>
                          xxxxxxxx
                        </CardBody>
                      </Card>
                    </Col>
                  {/* </Col> */}
                </Row>
              </Container>
            </div>
          

        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg19.jpg") + ")",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <h4 className="info-title">会社概要</h4>
                <div id="typography">
                  <Row>
                    <Col md="12">
                      <div className="typography-line">
                        <h6>
                          <span>会社名</span>
                          <p className="text-white">松井商事株式会社</p>
                        </h6>
                      </div>
                      <div className="typography-line">
                        <h6>
                          <span>代表者</span>
                          <p className="text-white">代表取締役社長　松井芳文</p>
                        </h6>
                      </div>
                      <div className="typography-line">
                        <h6>
                          <span>資本金</span>
                          <p className="text-white">800万円</p>
                        </h6>
                      </div>
                      <div className="typography-line">
                        <h6>
                          <span>設立日</span>
                          <p className="text-white">平成29年10月5日</p>
                        </h6>
                      </div>
                      <div className="typography-line">
                        <h6>
                          <span>従業員数</span>
                          <p className="text-white">3名</p>
                        </h6>
                      </div>
                      <div className="typography-line">
                        <h6>
                          <span>住所</span>
                          <p className="text-white">
                          〒142-0062<br />
                          東京都品川区小山6-13-2<br />
                          TEL / FAX 03-5734-1858
                          </p>
                        </h6>
                      </div>
                      <div className="typography-line">
                        <h6>
                          <span>事業内容</span>
                          <p className="text-white">
                          建設機械の中古買取、販売、輸出入<br />
                          建設機械レンタル<br />
                          国外市場進出のコンサルティング
                          </p>
                        </h6>
                      </div>
                      <div className="typography-line">
                        <h6>
                          <span>古物商</span>
                          <p className="text-white">
                          東京都公安委員会許可<br />
                          第302181707214号
                          </p>
                        </h6>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="5">
              <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons tech_mobile"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">お問い合わせ</h4>
                    <p className="description">
                      Tel：03-5734-1858<br></br>
                      Fax：03-5734-1858<br></br>
                      Email：sales@matsucorp.com
                    </p>
                  </div>
                </div>
                <Card className="card-contact card-raised">
                  <Form id="contact-form" method="post" role="form">
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Contact Us</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="pr-2" md="6">
                          <label>First name</label>
                          <InputGroup
                            className={
                              firstNameFocus ? "input-group-focus" : ""
                            }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons users_circle-08"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              autoComplete="name"
                              placeholder="First Name..."
                              type="text"
                              onFocus={() => setFirstNameFocus(true)}
                              onBlur={() => setFirstNameFocus(false)}
                            ></Input>
                          </InputGroup>
                        </Col>
                        <Col className="pl-2" md="6">
                          <FormGroup>
                            <label>Last name</label>
                            <InputGroup
                              className={
                                lastNameFocus ? "input-group-focus" : ""
                              }
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons text_caps-small"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                autoComplete="name"
                                placeholder="Last Name..."
                                type="text"
                                onFocus={() => setLastNameFocus(true)}
                                onBlur={() => setLastNameFocus(false)}
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <label>Email address</label>
                        <InputGroup
                          className={emailFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete="email"
                            placeholder="Email Here..."
                            type="email"
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <label>Your message</label>
                        <Input
                          id="message"
                          name="message"
                          rows="6"
                          type="textarea"
                        ></Input>
                      </FormGroup>
                      <Row>
                        <Col md="6">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox"></Input>
                              <span className="form-check-sign"></span>
                              I'm not a robot
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <Button
                            className="btn-round pull-right"
                            color="info"
                            type="submit"
                          >
                            Send Message
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
          </div>
        {/* </div> */}
        <Footer />
      {/* </div> */}
    </>
  );
}

export default DemoDetail;
