import React from "react";

// reactstrap components
import {
  // Button,
  // Card,
  // CardBody,
  // CardFooter,
  // CardTitle,
  // Label,
  // FormGroup,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
// import FixedTransparentNavbar from "components/Navbars/FixedTransparentNavbar.js";
// import Footer from "components/Footers/Footer.js";

function DemoUnder() {
  // const [firstFocus, setFirstFocus] = React.useState(false);
  // const [lastFocus, setLastFocus] = React.useState(false);
  // const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("signup-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("signup-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="page-header header-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg22.jpg") + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="6" lg="4">
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    {/* <i className="now-ui-icons media-2_sound-wave"></i> */}
                  </div>
                  <div className="description">
                    <h5 className="info-title">Coming soon...</h5>
                    <p className="description">
                    ホームページ・リニューアル中
                    </p>
                  </div>
                </div>
                
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    {/* <i className="now-ui-icons users_single-02"></i> */}
                  </div>
                  <div className="description">
                    <h5 className="info-title">お問合せ先</h5>
                    <p className="description">
                      松井商事株式会社<br />
                      <b>Tel：03-5734-1858 <br />
                      Fax：03-5734-1858 <br />
                      Email：sales@matsucorp.com <br /></b>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default DemoUnder;
