/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStock = /* GraphQL */ `
  mutation CreateStock(
    $input: CreateStockInput!
    $condition: ModelStockConditionInput
  ) {
    createStock(input: $input, condition: $condition) {
      id
      serial
      maker
      model
      hour
      year
      port
      displayOrder
      price
      condition
      memo
      youtubeUrl
      status
      category
      description
      gk
      image {
        bucket
        key
        region
        __typename
      }
      imageSmall {
        bucket
        key
        region
        __typename
      }
      imageMedium {
        bucket
        key
        region
        __typename
      }
      imageLarge {
        bucket
        key
        region
        __typename
      }
      isRental
      isPublish
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStock = /* GraphQL */ `
  mutation UpdateStock(
    $input: UpdateStockInput!
    $condition: ModelStockConditionInput
  ) {
    updateStock(input: $input, condition: $condition) {
      id
      serial
      maker
      model
      hour
      year
      port
      displayOrder
      price
      condition
      memo
      youtubeUrl
      status
      category
      description
      gk
      image {
        bucket
        key
        region
        __typename
      }
      imageSmall {
        bucket
        key
        region
        __typename
      }
      imageMedium {
        bucket
        key
        region
        __typename
      }
      imageLarge {
        bucket
        key
        region
        __typename
      }
      isRental
      isPublish
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStock = /* GraphQL */ `
  mutation DeleteStock(
    $input: DeleteStockInput!
    $condition: ModelStockConditionInput
  ) {
    deleteStock(input: $input, condition: $condition) {
      id
      serial
      maker
      model
      hour
      year
      port
      displayOrder
      price
      condition
      memo
      youtubeUrl
      status
      category
      description
      gk
      image {
        bucket
        key
        region
        __typename
      }
      imageSmall {
        bucket
        key
        region
        __typename
      }
      imageMedium {
        bucket
        key
        region
        __typename
      }
      imageLarge {
        bucket
        key
        region
        __typename
      }
      isRental
      isPublish
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      company
      name
      email
      tel
      message
      memo
      receivedAt
      isDelete
      stockId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      company
      name
      email
      tel
      message
      memo
      receivedAt
      isDelete
      stockId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      company
      name
      email
      tel
      message
      memo
      receivedAt
      isDelete
      stockId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNews = /* GraphQL */ `
  mutation CreateNews(
    $input: CreateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    createNews(input: $input, condition: $condition) {
      id
      publishAt
      message
      messageEn
      isPublish
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNews = /* GraphQL */ `
  mutation UpdateNews(
    $input: UpdateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    updateNews(input: $input, condition: $condition) {
      id
      publishAt
      message
      messageEn
      isPublish
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNews = /* GraphQL */ `
  mutation DeleteNews(
    $input: DeleteNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    deleteNews(input: $input, condition: $condition) {
      id
      publishAt
      message
      messageEn
      isPublish
      createdAt
      updatedAt
      __typename
    }
  }
`;
