import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Button,
    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter
  } from "reactstrap";

import NewsTable from "../../../admin_components/tables/NewsTable";

// GraphQL API
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify";
import { queryNewsGsiOrderedList } from '../../../graphql/queries'
import { createNews, updateNews, deleteNews } from '../../../graphql/mutations'
import CardFooter from "reactstrap/lib/CardFooter";

const initForm = {
    publishAt: '',
    message: '',
    messageEn: '',
}


const NewsPage = (props)=>{
    const [news, setNews] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [form, setForm] = useState(initForm)
    const [selectedNews, setSelectedNews] = useState(null)

    console.log('form', form)
    console.log('news', news)

    useEffect(() => {
        fetchNews(); 

    }, []);

    const fetchNews = async() =>{
        const publishList = await getOrderedNews(1)
        const nonPublichList = await getOrderedNews(0)
        const newsList = publishList.concat(nonPublichList)
        setNews(newsList)
    }

    const getOrderedNews = async( isPublish ) => {
        let newsList = []
        try{
            let nextToken = null
            const res = await API.graphql(graphqlOperation(queryNewsGsiOrderedList, {isPublish: isPublish, sortDirection:"DESC"}))
            const items = res.data.queryNewsGsiOrderedList.items
            newsList = newsList.concat(items)
            nextToken = res.data.queryNewsGsiOrderedList.nextToken
    
            while(nextToken!==null){
              const res = await API.graphql(graphqlOperation(queryNewsGsiOrderedList, {isPublish: isPublish, sortDirection:"DESC", nextToken: nextToken}))
              const items = res.data.queryNewsGsiOrderedList.items
              newsList = newsList.concat(items)
                nextToken = res.data.queryNewsGsiOrderedList.nextToken
            }
        
          }catch(err){
            console.log('error', err)
          }
          return newsList
    }

    const handleSubmit = async() => {
        console.log('handleSubmit')
        // validation
        if(form.publishAt===""){
            window.alert("日付を選択してください")
            return
        }

        if(form.message===""){
            window.alert("お知らせを入力してください")
            return
        }

        if(form.messageEn===""){
            window.alert("お知らせ(English)を入力してください")
            return
        }

        let inputData = {
            publishAt: form.publishAt,
            message: form.message,
            messageEn: form.messageEn,
            isPublish: 1,
        }

        console.log('__inputData', inputData)

        if(selectedNews){
            console.log('update', selectedNews)
            inputData.id = selectedNews
            try{
                const res = await API.graphql(graphqlOperation(updateNews, {input: inputData}))
                console.log('res', res)

            }catch(err){
                console.log('error', err)
            }

        }else{
            console.log('create')
            try{
                const res = await API.graphql(graphqlOperation(createNews, {input: inputData}))
                console.log('res', res)

            }catch(err){
                console.log('error', err)
            }
        }
        setForm(initForm)
        setIsModalOpen(false)
        fetchNews()
    }

    const handleDeleteNews = async() =>{
        console.log('deleteNews')

        try{
            const inputData = {
                id: selectedNews
            }

            const res = await API.graphql(graphqlOperation(deleteNews, {input: inputData}))
            console.log('res', res)

        }catch(err){
            console.log('error', err)
        }

        setForm(initForm)
        setIsDeleteModalOpen(false)
        fetchNews()
    }


    return (
        <>
            {/* header */}
            <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                <Container fluid>
                <div className="header-body">
                    <Row className="align-items-center py-4">
                    <Col lg="6" xs="7">
                        <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                            お知らせ管理
                        </h6>{" "}
                    </Col>
                    <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                        {/* 初期運用は3件のみを表示するため、news.lengthで表示制御 */}
                        {news.length<=2 ? <Button color="default" type="button"　size="sm" onClick={() => setIsModalOpen(true)}>新規作成</Button>: null }
                    </Col>
                    </Row>
                </div>
                </Container>
            </div>

            {/* search & filter */}
            <Container className="mt--6" fluid>
                <Row>
                    <Col xs="12">
                        <Card className="mb-4">
                        <CardBody>
                            <NewsTable 
                                news={news} 
                                setSelectedNews={setSelectedNews}
                                setForm={setForm}
                                setIsModalOpen={setIsModalOpen}
                                setIsDeleteModalOpen={setIsDeleteModalOpen}
                            />
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* 登録/編集モーダル */}
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(false)}
            >
                <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                    <Form>
                        <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="publish-at"
                            >
                            日付（年月日）
                            </label>
                            <Input
                            defaultValue={form.publishAt}
                            id="publish-at"
                            type="date"
                            onChange={e => setForm({ ...form, 'publishAt': e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="message"
                            >
                            お知らせ（一行）
                            </label>
                            <Input
                            defaultValue={form.message}
                            id="message"
                            type="text"
                            onChange={e => setForm({ ...form, 'message': e.target.value})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label
                            className="form-control-label"
                            htmlFor="messageEn"
                            >
                            お知らせ（English）
                            </label>
                            <Input
                            defaultValue={form.messageEn}
                            id="messageEn"
                            type="text"
                            onChange={e => setForm({ ...form, 'messageEn': e.target.value})}
                            />
                        </FormGroup>
                    </Form>
                    </CardBody>
                    <CardFooter className="text-center">
                        <Button
                            className="my-4"
                            color="default"
                            type="button"
                            size="sm"
                            onClick={() => setIsModalOpen(false)}
                        >
                            閉じる
                        </Button>
                        <Button
                            className="my-4"
                            color="info"
                            type="button"
                            size="sm"
                            onClick={() => handleSubmit()}
                        >
                            登録
                        </Button>
                    </CardFooter>
                </Card>
                </div>
            </Modal>
            {/* 削除モーダル */}
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(false)}
            >
                <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        {form.message}を削除してよろしいでしょうか？
                    </CardBody>
                    <CardFooter className="text-center">
                        <Button
                            className="my-4"
                            color="default"
                            type="button"
                            size="sm"
                            onClick={() => setIsDeleteModalOpen(false)}
                        >
                            閉じる
                        </Button>
                        <Button
                            className="my-4"
                            color="info"
                            type="button"
                            size="sm"
                            onClick={() => handleDeleteNews()}
                        >
                            削除
                        </Button>
                    </CardFooter>
                </Card>
                </div>
            </Modal>
        </>
    )
}

export default NewsPage;