import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

// core components
import CorpNavbar from "../../../components/Navbars/CorpNavbar.js";
import Footer from "../../../components/Footers/CorpFooter.js";
// SEO
import {Helmet} from "react-helmet";
// Font
import GoogleFontLoader from 'react-google-font-loader';


const GreetingPage = () => {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`松井商事株式会社 代表挨拶`}</title>
          <meta name="description" content={`松井商事株式会社は「世界の架け橋となる企業を目指して」をモットーに、輸出販売をメインとした中古建設機械の専門商社として平成29年に創業いたしました。油圧ショベルや、大型重機、発電機等の各種建設機械を日本から世界各国のお客様へと輸出し、並びに、開発途上国のインフラ整備などのODA事業に必要な建設機械の現地調達や、第三国への輸出も行っております。`} />
          <meta name="keywords" content= {`松井商事,中古,中古建機,関東,茨城,かすみがうら,ショベル,ミニショベル,ブルドーザー,ホイールローダー`}/>
      </Helmet>
      {/* https://fonts.google.com/?subset=japanese */}
      <GoogleFontLoader
        fonts={[
          {
            font: 'Kokoro',
            weights: [800],
          },
          {
            font: 'Hannari',
            weights: [400],
          },
          {
            // Noto Serif JP, serif
            font: 'Noto Serif JP',
            weights: [200],
          },
          {
            // New Tegomin, serif
            font: 'New Tegomin',
            weights: [400],
          },
          {
            // Hachi Maru Pop, cursive
            font: 'Hachi Maru Pop',
            weights: [400],
          },
        ]}
      />

      <CorpNavbar />

      <div className="page-header header-filter" filter-color="blue">
        {/* <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/login.jpg") + ")",
          }}
        ></div> */}

        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{ color: 'white'}}>代表挨拶</h2>
              </Col>
            </Row>
            <Row>
            <Col className="ml-auto mr-auto" md="12">
                <Card className="card-product">
                    {/* <CardHeader className="text-center">
                    <   CardTitle tag="h2">代表挨拶</CardTitle>
                    </CardHeader> */}
                    {/* <CardBody className="text-black" style={{ fontFamily: 'Noto Serif JP, serif' }}> */}
                    <CardBody className="text-black" style={{ fontFamily: 'New Tegomin, serif' }}>
                      <b>
                        <div className="pr-2 text-left" md="12" style={{ fontSize: 20 }}>
                        松井商事株式会社は「世界の架け橋となる企業を目指して」をモットーに、輸出販売をメインとした中古建設機械の専門商社として平成29年に創業いたしました。<br /><br />
                        油圧ショベルや、大型重機、発電機等の各種建設機械を日本から世界各国のお客様へと輸出し、並びに、開発途上国のインフラ整備などのODA事業に必要な建設機械の現地調達や、第三国への輸出も行っております。<br /><br />
                        また、各種建設機械のレンタル・販売のご提案を行っております、多様化する日本国内における工事ニーズにおいて、お客様に最適なご提案をさせて頂くよう全社一丸となり取り組んでおります。<br /><br />
                        経営者としてまだまだ未熟ではありますが、これからも全てのお客様のご満足と全ての従業員の幸せのために日々誠実な仕事をしていきたいと思います。また企業として社会に貢献し、企業価値を高め、存在意義のある企業をあるべき姿としてとらえ、積極的に新分野にチャレンジして参ります。<br /><br />
                        今後とも尚一層のご支援とご愛顧を賜りますよう、お願い申し上げます
                        </div>
    　　　　　　　　　　　　<div className="text-right" style={{ fontSize: 20 }}>代表取締役社長　松井 芳文</div>
                      </b>
                    </CardBody>
                </Card>
            </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default GreetingPage;
