import React, {useState, useEffect} from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledCarousel,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
// reactstrap components

// Core Components
import { v4 as uuid } from 'uuid'
import {useDropzone} from 'react-dropzone'
import Dropzone from "dropzone";
import Compress from "react-image-file-resizer";

// amplify
import { getStock, downloadStockImage } from '../../graphql/queries'
import { updateStock } from '../../graphql/mutations'
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify";
import awsExports from "../../aws-exports";
import StockPage from "pages/admin/stock/StockPage";
Amplify.configure(awsExports);
// Storage.configure({ level: 'public' });
Dropzone.autoDiscover = false;

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = awsExports


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};
const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};
const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const FileUploadForm = ( props ) => {
  const [stock, setStock] = useState();
  const [picName, setPicName] = useState("");
  const [pictures, setPictures] = useState([]);
  const [picturesCol, setPicturesCol] = useState([]);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
        })));
    }
   }); 

  // console.log('acceptedFiles', acceptedFiles)
  // console.log('stock', stock)

  useEffect(() => {
    fetchPicture();
  }, [])

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const fetchPicture = async() =>{
    try {
      const res = await API.graphql(graphqlOperation(getStock, {id: props.stockId}))
      console.log('res', res)
      setStock(res.data.getStock)
      const images = res.data.getStock.imageSmall

      let imageList = [];
      let imageColList = [];
      for(const [index, image] of images.entries()){
        let imageUrl = await Storage.get(image.key)

        let imageObj = image
        imageObj['url'] = imageUrl
        console.log('imageObj', imageObj)
        imageList = imageList.concat(imageObj)
        setPictures(imageList)

        // カルーセル表示用
        let carouselItem = {
          src: imageUrl,
          altText: "Slide 1",
          caption: `cap-${index}`,
          header: `head-${index}`,
          key: index,
        }
        imageColList = imageColList.concat(carouselItem)
        setPicturesCol(imageList)
      }      
     } catch(err) {
       console.log('error fetching pictures', err)
     }
  }

  const handleUplaodFiles = async() =>{
    setIsUploading(true)
    const stockId = props.stockId
    let image = []
    let imageSmall = []
    let imageMedium = []
    let imageLarge = []

    // ファイルのnameでソートする
    const sortedAceptedFiles = acceptedFiles.sort((a, b) => {
			if(a.name > b.name){
				return 1
			}else{
				return -1
			}
		})

    for(const file of sortedAceptedFiles){
      // console.log('drop file', file)
      let fileName = file.name;
      const result = await putPicture(file);
      if(result === 'ok'){
        console.log('uploaded: ok')
        image.push({
          bucket,
          key: `${stockId}/org/${fileName}`,
          region,
        });
        imageSmall.push({
          bucket,
          key: `${stockId}/small/${fileName}`,
          region,
        });
        imageMedium.push({
          bucket,
          key: `${stockId}/medium/${fileName}`,
          region,
        });
        imageLarge.push({
          bucket,
          key: `${stockId}/large/${fileName}`,
          region,
        });     
      }else{
        console.log('upload failed:', fileName)
        setIsUploading(false)
        return
      }  
    }

    // delete archive file
    try{
      await Storage.remove(`${stockId}/org/archive.zip`, { level: 'public' })
      console.log('remove done')
    }catch(err){
      console.log('error', err)
    }
    

    // insert　s3path to db
    try{
      const updateInput = {
        id: props.stockId,
        image,
        imageSmall,
        imageMedium,
        imageLarge,
      }
      const res = await API.graphql(graphqlOperation(updateStock, {input: updateInput}))
      console.log('res', res)

      // await deleteOldPictures();

      setFiles([])
      fetchPicture()
      setIsUploading(false)
    
    }catch(err){
      console.log('error', err)
      setIsUploading(false)
      return
    }
  }

  const putPicture = async(newUpFile) => {
    const { name: fileName, type: mimeType } = newUpFile  
    const stockId = props.stockId
    const key = `${stockId}/org/${fileName}`
    console.log('newUpFile', newUpFile)
    try {

      Compress.imageFileResizer(
        newUpFile, // the file from input
        100, // width
        70, // height
        newUpFile.extend, // compress format WEBP, JPEG, PNG
        100, // quality
        0, // rotation
        (uri) => {
          console.log(uri);
          Storage.put(`${stockId}/small/${fileName}`, uri, {
            level: 'public',
            contentType: mimeType
          })
        },
        "blob" // blob or base64 default base64
      );

      Compress.imageFileResizer(
        newUpFile, // the file from input
        180, // width
        145, // height
        newUpFile.extend, // compress format WEBP, JPEG, PNG
        100, // quality
        0, // rotation
        (uri) => {
          console.log(uri);
          Storage.put(`${stockId}/medium/${fileName}`, uri, {
            level: 'public',
            contentType: mimeType
          })
        },
        "blob" // blob or base64 default base64
      );
      
      Compress.imageFileResizer(
        newUpFile, // the file from input
        700, // width
        500, // height
        newUpFile.extend, // compress format WEBP, JPEG, PNG
        100, // quality
        0, // rotation
        (uri) => {
          console.log(uri);
          Storage.put(`${stockId}/large/${fileName}`, uri, {
            level: 'public',
            contentType: mimeType
          })
        },
        "blob" // blob or base64 default base64
      );

      await Storage.put(`${stockId}/org/${fileName}`, newUpFile, {
        level: 'public',
        contentType: mimeType
      })
    //   await API.graphql(graphqlOperation(createPicture, { input: inputData }))    

      console.log('successfully stored user data!')
      return 'ok'

    } catch (err) {
      console.log('error: ', err)
      return 'NG'

    }
  }
  
  const deleteOldPictures = async() =>{
    // todo: delete s3 data
    try{
      for(const [index, image] of stock.imageSmall.entries()){
        let result = await Storage.remove(image.key)
        console.log('result', result)
      }
      for(const [index, image] of stock.imageMedium.entries()){
        let result = await Storage.remove(image.key)
        console.log('result', result)
      }
      for(const [index, image] of stock.imageLarge.entries()){
        let result = await Storage.remove(image.key)
        console.log('result', result)
      }
      for(const [index, image] of stock.image.entries()){
        let result = await Storage.remove(image.key)
        console.log('result', result)
      }
      return 'ok'
    }catch(err){
      console.log('error', err)
      return 'error'
    }
  }


  const handleDownload = async() =>{
    console.log('downloading...')
    setIsDownloading(true)
    let downloadUrl = ''
    try{
      // API
      const res = await API.graphql(graphqlOperation(downloadStockImage, {stockId: props.stockId}))
      console.log('res', res)
      const data = JSON.parse(res.data.downloadStockImage)
      downloadUrl = data.data.downloadUrl
      console.log('downloadUrl', downloadUrl)
      window.location.href = downloadUrl


    }catch(err){
      console.log('error', err)
    }
    
    setIsDownloading(false)
  }

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
        <div style={thumbInner}>
        <img
            src={file.preview}
            style={img}
        />
        </div>
    </div>
   ));


  return (
    <>
      <Card className="bg-gradient-default">
      <CardBody>
        <Card className="bg-secondary shadow border-0">
            <CardBody>
            <section className="container">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>
                        アップロードするファイルを一括でDrag＆Dropしてください。<br />
                        <small>※ファイル名順で表示されます</small><br />
                        <small>※やり直す場合は、再度すべてのファイルをDrag&Dropしてください</small>
                    </p>
                </div>
                <aside style={thumbsContainer}>
                    {thumbs}
                </aside>
            </section>
            </CardBody>
        </Card>
        
        {files.length===0 ? null
        : 
          <div className="text-center">
            {isUploading ? 
              <>   
              <Button className="my-4" color="primary" type="button">
                <Spinner color="secondary" /> uploading
              </Button>   
              </>  
            : 
              <Button className="my-4" color="primary" type="button" onClick={ () => handleUplaodFiles() }>
                upload
              </Button>
            }
          </div>
        }
      </CardBody>
      </Card>
      <Card className="bg-gradient-default">
          <CardBody className="px-lg-3 py-lg-3">
            <div className="text-center text-muted mb-4">
                <small>現アップロード画像</small>
            </div>
            <Row>
            <Col md="12" className="mx-auto">
                <footer className="footer footer-big footer-white">
                    <div className="content">
                        <div className="gallery-feed">
                        {pictures.map((item, index) => {
                            return (
                                <img
                                    key={`current-${index+1}`}
                                    alt={index+1}
                                    className="img img-raised rounded"
                                    caption={index+1}
                                    src={item.url}
                                />    
                            );
                        })}                    
                        </div>
                    </div>              
                </footer> 
                <div align='center'>
                {isDownloading ? 
                  <>   
                  <Button className="my-4" color="primary" type="button" size="sm">
                    <Spinner color="secondary" /> downloading
                  </Button>   
                  </>  
                : pictures.length===0 ? null
                  :
                    <Button className="my-4" color="primary" type="button" size="sm" onClick={ () => handleDownload() }>
                      download
                    </Button>
                }
                </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default FileUploadForm;
