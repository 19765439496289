import enTranslation from './en/translation';
import jaTranslation from './ja/translation';

export default {
  en: {
    translation: enTranslation
  },
  ja: {
    translation: jaTranslation
  }
};