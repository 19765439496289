import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";


const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;


const StockTables = ( props ) => {
  const history = useHistory()

  return (
    <>
      <ToolkitProvider
        data={props.stocks}
        keyField="id"
        columns={[
          {
            dataField: "displayOrder",
            text: "-",
            sort: true,
            formatter: (cell, row) => {
              return(
                <>
                  {row.displayOrder===0 ? null : row.displayOrder}
                </>
              )
            },
          },
          {
            dataField: "model",
            text: "Model",
            sort: true
          },
          {
            dataField: "serial",
            text: "serial",
            sort: true
          },
          {
            dataField: "gk",
            text: "gk",
            sort: true,
            formatter: (cell, row) => {
              return(
                <>
                  {row.gk ? row.gk.toLocaleString() : null }
                </>
              )
            },
          },
          {
            dataField: "price",
            text: "price",
            sort: true,
            formatter: (cell, row) => {
              return(
                <>
                  {row.price>=0 ? row.price.toLocaleString() 
                    : `(${row.price.toLocaleString()})`  }
                </>
              )
            },
          },
          {
            dataField: "status",
            text: "status",
            sort: true,
            formatter: (cell, row) => {
              return(
                <>
                  {row.status==='New' ? <Badge color="warning" className="mr-1">PickUp</Badge>
                    : row.status==='Hold' ? <Badge color="info" className="mr-1">Hold</Badge>
                    : row.status==='Sold' ? <Badge color="secondary" className="mr-1">Sold</Badge>
                    : row.status==='None' ? null : row.status
                  }
                </>
              )
            },
          },
          {
            dataField: "isPublish",
            text: "Public",
            sort: true,
            formatter: (cell, row) => {
              return(
                <>
                  {row.isPublish===1 ? <Badge color="default" className="mr-1">公開</Badge>
                    : <Badge color="secondary" className="mr-1">非公開</Badge>
                  }
                </>
              )
            },
          },
          {
            dataField: "",
            text: "",
            sort: false,
            formatter: (cell, row) => {
              return(
                <>
                  {/* <i className={`${row.icon}`} /> */}
                  <Button color='info' size="sm" onClick={ () => {history.push( "/admin/stock/"+row.id);}}>編集</Button>
                </>
              )
            },
          },
          {
            dataField: "",
            text: "",
            sort: false,
            formatter: (cell, row) => {
              return(
                <>
                  {/* <i className={`${row.icon}`} /> */}
                  <Button color='danger' size="sm" onClick={ () => { props.setSelectedStock(row); props.setIsDeleteModalOpen(true); } }>削除</Button>
                </>
              )
            },
          },
        ]}
        search
      >
        {props => (
          <div className="py-4">
            <div
              id="datatable-basic_filter"
              className="dataTables_filter px-4 pb-1"
            >
              <label>
                <SearchBar
                  className="form-control-sm"
                  placeholder=""
                  {...props.searchProps}
                />
              </label>
            </div>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
}

export default StockTables;
