import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardLink,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import CorpNavbar from "../../../components/Navbars/CorpNavbar.js";
import CorpStockListHeader from "../../../components/Headers/CorpStockListHeader.js";
import Footer from "../../../components/Footers/CorpFooter.js";

// component
import StockCard from "../../../components/card/StockCard.js";

// i18n
import '../../../i18n';
import { useTranslation } from 'react-i18next';

// SEO
import {Helmet} from "react-helmet";

// Amplify
import Amplify, { Auth, Storage, API, graphqlOperation } from "aws-amplify";
import { queryStockGsiOrderedList } from '../../../graphql/queries'
Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"});
console.log('auth', Auth.currentAuthenticatedUser)
Auth.currentAuthenticatedUser()
  .then(data => {Amplify.configure({aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"}); console.log('user-pools')})
  .catch(err => {Amplify.configure({aws_appsync_authenticationType: "AWS_IAM"}); console.log('iam')});


const items = [
  {
    src: require("assets/img/pp-1.jpg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/pp-2.jpg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/pp-3.jpg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/pp-4.jpg"),
    altText: "",
    caption: "",
  },
];

const StockList = () => {
  // carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const [stocks, setStocks] = React.useState([]);
  const [ t, i18n ] = useTranslation();

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  // select states and functions
  const [colorSelect, setColorSelect] = React.useState(null);
  const [sizeSelect, setSizeSelect] = React.useState(null);
  React.useEffect(() => {
    document.body.classList.add("product-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("product-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);


  React.useEffect(() => {
    fetchStocks(); 

  }, []);

  const fetchStocks = async() =>{
      try{
        let stockList = []
        let nextToken = null
        const res = await API.graphql(graphqlOperation(queryStockGsiOrderedList, {isPublish:1, sortDirection:"ASC"}))
        const items = res.data.queryStockGsiOrderedList.items
        for(const item of items){
          let stockItem = item
          try{
            const photoUrl = await Storage.get(item.imageMedium[0].key)
            stockItem['photoUrl'] = photoUrl
          }catch(err){
            stockItem['photoUrl'] = null
          }
          stockList.push(stockItem) 
        }
        nextToken = res.data.queryStockGsiOrderedList.nextToken

        while(nextToken!==null){
          const res = await API.graphql(graphqlOperation(queryStockGsiOrderedList, {isPublish:1, sortDirection:"ASC", nextToken: nextToken}))
          const items = res.data.queryStockGsiOrderedList.items
          for(const item of items){
            let stockItem = item
            try{
              const photoUrl = await Storage.get(item.imageMedium[0].key)
              stockItem['photoUrl'] = photoUrl
            }catch(err){
              stockItem['photoUrl'] = null
            }
            stockList.push(stockItem) 
          }
          nextToken = res.data.queryStockGsiOrderedList.nextToken
        }

        console.log('stockList', stockList)
        setStocks(stockList)

      }catch(err){
        console.log('error', err)
      }
  }


  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>松井商事株式会社 在庫一覧</title>
          <meta name="description" content="松井商事株式会社 在庫一覧" />
          <meta name="keywords" content= {`松井商事,中古,中古建機,関東,茨城,かすみがうら,ショベル,ミニショベル,ブルドーザー,ホイールローダー`}/>
      </Helmet>
      <CorpNavbar />
      <div className="wrapper">
        <CorpStockListHeader />

        {/* <div className="section related-products" data-background-color="black"> */}
        <div className="section related-products" data-background-color="">
          <Container>
            {/* <h3 className="title text-center">
              You may also be interested in:
            </h3> */}
            <Row>
            {stocks.map((stock, index) => {
              return (
                <Col md="3" sm="6" key={index}>
                  <StockCard stock={stock} />
                </Col>
              )
            }
            )}
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default StockList;
