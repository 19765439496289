/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const downloadStockImage = /* GraphQL */ `
  query DownloadStockImage($stockId: String) {
    downloadStockImage(stockId: $stockId)
  }
`;
export const sendEmail = /* GraphQL */ `
  query SendEmail($data: AWSJSON) {
    sendEmail(data: $data)
  }
`;
export const getStock = /* GraphQL */ `
  query GetStock($id: ID!) {
    getStock(id: $id) {
      id
      serial
      maker
      model
      hour
      year
      port
      displayOrder
      price
      condition
      memo
      youtubeUrl
      status
      category
      description
      gk
      image {
        bucket
        key
        region
      }
      imageSmall {
        bucket
        key
        region
      }
      imageMedium {
        bucket
        key
        region
      }
      imageLarge {
        bucket
        key
        region
      }
      isRental
      isPublish
      createdAt
      updatedAt
    }
  }
`;
export const listStocks = /* GraphQL */ `
  query ListStocks(
    $filter: ModelStockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serial
        maker
        model
        hour
        year
        port
        displayOrder
        price
        condition
        memo
        youtubeUrl
        status
        category
        description
        gk
        image {
          bucket
          key
          region
        }
        imageSmall {
          bucket
          key
          region
        }
        imageMedium {
          bucket
          key
          region
        }
        imageLarge {
          bucket
          key
          region
        }
        isRental
        isPublish
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryStockGsiOrderedList = /* GraphQL */ `
  query QueryStockGsiOrderedList(
    $isPublish: Int
    $displayOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryStockGsiOrderedList(
      isPublish: $isPublish
      displayOrder: $displayOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serial
        maker
        model
        hour
        year
        port
        displayOrder
        price
        condition
        memo
        youtubeUrl
        status
        category
        description
        gk
        image {
          bucket
          key
          region
        }
        imageSmall {
          bucket
          key
          region
        }
        imageMedium {
          bucket
          key
          region
        }
        imageLarge {
          bucket
          key
          region
        }
        isRental
        isPublish
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryStockLsiListStocks = /* GraphQL */ `
  query QueryStockLsiListStocks(
    $id: ID
    $displayOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryStockLsiListStocks(
      id: $id
      displayOrder: $displayOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serial
        maker
        model
        hour
        year
        port
        displayOrder
        price
        condition
        memo
        youtubeUrl
        status
        category
        description
        gk
        image {
          bucket
          key
          region
        }
        imageSmall {
          bucket
          key
          region
        }
        imageMedium {
          bucket
          key
          region
        }
        imageLarge {
          bucket
          key
          region
        }
        isRental
        isPublish
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      company
      name
      email
      tel
      message
      memo
      youtubeUrl
      receivedAt
      isDelete
      stockId
      createdAt
      updatedAt
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        name
        email
        tel
        message
        memo
        youtubeUrl
        receivedAt
        isDelete
        stockId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryContactGsiOrderedList = /* GraphQL */ `
  query QueryContactGsiOrderedList(
    $isDelete: Int
    $receivedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryContactGsiOrderedList(
      isDelete: $isDelete
      receivedAt: $receivedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company
        name
        email
        tel
        message
        memo
        youtubeUrl
        receivedAt
        isDelete
        stockId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews($id: ID!) {
    getNews(id: $id) {
      id
      publishAt
      message
      messageEn
      isPublish
      createdAt
      updatedAt
    }
  }
`;
export const listNewss = /* GraphQL */ `
  query ListNewss(
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        publishAt
        message
        messageEn
        isPublish
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryNewsGsiOrderedList = /* GraphQL */ `
  query QueryNewsGsiOrderedList(
    $isPublish: Int
    $publishAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryNewsGsiOrderedList(
      isPublish: $isPublish
      publishAt: $publishAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        publishAt
        message
        messageEn
        isPublish
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
