import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";

// admin
// admin_plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// admin_plugins styles downloaded
import "admin_assets/vendor/nucleo/css/nucleo.css";
// admin_core styles
import "admin_assets/css/argon-dashboard.css";
// admin
import AdminLayout from "./layouts/Admin.js";


// pages
import AboutUs from "views/examples/AboutUs.js";
import BlogPost from "views/examples/BlogPost.js";
import BlogPosts from "views/examples/BlogPosts.js";
import ContactUs from "views/examples/ContactUs.js";
import Ecommerce from "views/examples/Ecommerce.js";
import Index from "views/Index.js";
import LandingPage from "views/examples/LandingPage.js";
import LoginPage from "views/examples/LoginPage.js";
import NucleoIcons from "views/NucleoIcons.js";
import Presentation from "views/Presentation.js";
import Pricing from "views/examples/Pricing.js";
import ProductPage from "views/examples/ProductPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import Sections from "views/Sections.js";
import SignupPage from "views/examples/SignupPage.js";
// others
import Demo from "views/Demo.js";
import DemoDetail from "views/DemoDetail.js";
import DemoList from "views/DemoList.js";
import DemoUnder from "views/DemoUnder.js";


// for production 
import IndexPage from './pages/user/home/index'
import GreetingPage from './pages/user/home/greeting'
import StockList from './pages/user/stock/StockList'
import StockDetail from './pages/user/stock/StockDetail'
import StockDetailWithName from './pages/user/stock/StockDetailWithName'

ReactDOM.render(
  <BrowserRouter>
    {/* <Switch>
      <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
      <Route path="/blog-post" render={(props) => <BlogPost {...props} />} />
      <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} />
      <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
      <Route path="/e-commerce" render={(props) => <Ecommerce {...props} />} />
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route
        path="/presentation"
        render={(props) => <Presentation {...props} />}
      />
      <Route path="/pricing" render={(props) => <Pricing {...props} />} />
      <Route
        path="/product-page"
        render={(props) => <ProductPage {...props} />}
      />
      <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
      <Route path="/sections" render={(props) => <Sections {...props} />} />
      <Route path="/sign-up" render={(props) => <SignupPage {...props} />} />
      <Route path="/demo" render={(props) => <Demo {...props} />} />
      <Route path="/demo-detail" render={(props) => <DemoDetail {...props} />} />
      <Route path="/demo-list" render={(props) => <DemoList {...props} />} />
      <Route path="/demo-under" render={(props) => <DemoUnder {...props} />} />
      <Redirect to="/presentation" />
    </Switch> */}

    <Switch>
      {/* テンプレート */}
      {/* <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
      <Route path="/blog-post" render={(props) => <BlogPost {...props} />} />
      <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} />
      <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
      <Route path="/e-commerce" render={(props) => <Ecommerce {...props} />} />
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route
        path="/presentation"
        render={(props) => <Presentation {...props} />}
      />
      <Route path="/pricing" render={(props) => <Pricing {...props} />} />
      <Route
        path="/product-page"
        render={(props) => <ProductPage {...props} />}
      />
      <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
      <Route path="/sections" render={(props) => <Sections {...props} />} />
      <Route path="/sign-up" render={(props) => <SignupPage {...props} />} /> */}
 
      {/* 本番想定 */}
      <Route path="/stock/list" render={(props) => <StockList {...props} />} />
      <Route path="/stock/:model/:serial" exact render={(props) => <StockDetailWithName {...props} />} />
      <Route path="/stock/:stockId" exact render={(props) => <StockDetail {...props} />} />
      <Route path="/greeting" render={(props) => <GreetingPage {...props} />} />
      {/* 管理画面 */}
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      {/* 工事中 */}
      {/* <Route path="/" render={(props) => <DemoUnder {...props} />} /> */}
      <Route path="/" render={(props) => <IndexPage {...props} />} />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
