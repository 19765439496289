import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Button,
    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter
  } from "reactstrap";

import { useParams } from 'react-router-dom';
import FileUploadForm from '../../../admin_components/form/FileUploadForm'

// GraphQL API
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify";
import { createStock, updateStock } from '../../../graphql/mutations'
import { getStock, queryStockGsiOrderedList } from '../../../graphql/queries'


const initStock = {
    serial: '',
    maker: '',
    model: '',
    hour: '',
    year: '',
    port: '',
    displayOrder: null,
    price: null,
    condition: '',
    memo: '',
    youtubeUrl: '',
    status: null,
    category: null,
    gk: null,
    isRental: 0,
    isPublish: 0,
}

const StockDetailPage = (props)=>{

    const [stock, setStock] = useState(initStock)
    const [stockOrg, setStockOrg] = useState(initStock)
    const [stockOrders, setStockOrders] = useState([])
    const { stockId } = useParams()

    console.log('stock', stock)
    console.log('stockId', stockId)

    useEffect(() => {
        console.log('effect')
        fetchStockOrders()
        if(stockId!=='new') fetchStock()
        
        
    }, [stockId]);

    const fetchStock = async() => {
        const res = await API.graphql(graphqlOperation(getStock, {id: stockId}))
        let resData = res.data.getStock
        delete resData.createdAt
        delete resData.updatedAt
        setStock(resData)
        setStockOrg(resData)
    }

    const fetchStockOrders = async() => {
        try{
            const stockList = await getOrderedStock(1)
            let stockOrderList = [...Array(stockList.length)].map((_, i) => i + 1) 
            if(stockId==='new') stockOrderList.push(stockOrderList.length + 1)
            console.log('stockOrderList', stockOrderList)
            setStockOrders(stockOrderList)
        
        }catch(err){
            console.log('error', err)
        }
    }

    const handleSubmit = async() => {
        console.log('handleSubmit', stock)

        // todo: バリデーション

        if(stockId==='new'){
            console.log('create a stock')
            try{
                const resCreate = await API.graphql(graphqlOperation(createStock, {input: stock}))
                console.log('resCreate', resCreate)
                const createdStockId = resCreate.data.createStock.id
                console.log('createdStockId', createdStockId)
                props.history.push(`/admin/stock/${createdStockId}`)

            }catch(err){
                console.log('error', err)
                return
            }
    
        }else{
            console.log('update the stock')
            const resUpdate = await API.graphql(graphqlOperation(updateStock, {input: stock}))
            console.log('resUpdate', resUpdate)
            const updatedStockId = resUpdate.data.updateStock.id
            console.log('updatedStockId', updatedStockId)
            props.history.push(`/admin/stock/${updatedStockId}`)

        }

        // 表示順を更新
        await updateDisplayOrder()
        props.history.push("/admin/stock");
    }
    
    const updateDisplayOrder = async() =>{
        try{
            const stockList = await getOrderedStock(1)
            console.log('__stockList', stockList)
            
            if(stockId==='new'){
                // 新規作成の場合、指定した表示順を差し込む
                for(const item of stockList){
                    if(item.displayOrder >= stock.displayOrder && item.id!==stock.id){
                        const input = {
                            id: item.id,
                            displayOrder: item.displayOrder + 1
                        }
                        console.log('__input', input)
                        const resUpdate = await API.graphql(graphqlOperation(updateStock, {input}))
    
                    }
                }
            }else{
                // 編集の場合、変更前後の順番を入れ替える
                for(const item of stockList){
                    // console.log('-----------')
                    // console.log(`item.displayOrder: ${item.displayOrder} stock.displayOrder ${stock.displayOrder}  `)
                    // console.log(item.displayOrder==stock.displayOrder)
                    // console.log('-----------')
                    // if(item.displayOrder==stock.displayOrder && item.id!=stock.id){
                    //     if(stockOrg.displayOrder===0 ){
                    //         const input = {
                    //             id: item.id,
                    //             displayOrder: item.displayOrder + 1
                    //         }
                    //         console.log('__input', input)
                    //         const resUpdate = await API.graphql(graphqlOperation(updateStock, {input}))

                    //     }
                        // || item.displayOrder <= stockOrg.displayOrder
                        // }else{
                        //     const input = {
                        //         id: item.id,
                        //         displayOrder: stockOrg.displayOrder
                        //     }
                        //     console.log('__input', input)
                        //     const resUpdate = await API.graphql(graphqlOperation(updateStock, {input}))
                        // }
                        
                    if(stockOrg.isPublish===1 && stock.isPublish===0){
                        // 非公開にした場合
                        if(item.displayOrder > stockOrg.displayOrder){
                            const input = {
                                id: item.id,
                                displayOrder: item.displayOrder - 1
                            }
                            console.log('__input', input)
                            const resUpdate = await API.graphql(graphqlOperation(updateStock, {input}))
                        }
                    }else if(stock.isPublish===1 && stock.displayOrder===0){
                        // 非公開から公開に変更して、表示順を変更しなった場合(画面表示上 1)、1にする
                        if(stock.id==item.id){
                            const input = {
                                id: item.id,
                                displayOrder: 1
                            }
                            console.log('__input', input)
                            const resUpdate = await API.graphql(graphqlOperation(updateStock, {input}))
                        }else{
                            const input = {
                                id: item.id,
                                displayOrder: item.displayOrder + 1
                            }
                            console.log('__input', input)
                            const resUpdate = await API.graphql(graphqlOperation(updateStock, {input}))
                        }
                    }else if(item.displayOrder==stock.displayOrder && item.id!=stock.id && stockOrg.displayOrder===0){
                        // 表示順が指定なし（0）の場合、先頭に差し込むため、他の在庫は+1する
                        const input = {
                            id: item.id,
                            displayOrder: item.displayOrder + 1
                        }
                        console.log('__input', input)
                        const resUpdate = await API.graphql(graphqlOperation(updateStock, {input}))
                    
                    }else if(item.id!=stock.id && stock.displayOrder<=item.displayOrder && item.displayOrder < stockOrg.displayOrder){
                        // 元の順序より小さい順序に変更した場合、他の建機は一つ下がる（例：4番目から2番目に挿入した場合、変更前に2番目以降だった建機は順序を+1する
                        const input = {
                            id: item.id,
                            displayOrder: item.displayOrder + 1
                        }
                        console.log('__input', input)
                        const resUpdate = await API.graphql(graphqlOperation(updateStock, {input}))

                    }else if(item.id!=stock.id && stock.displayOrder>=item.displayOrder && item.displayOrder >= stockOrg.displayOrder){
                        // 元の順序より大きい順序に変更した場合、他の建機は一上げる（例：1番目から4番目に挿入した場合、変更前の2-4番目は順序を-1する
                        const input = {
                            id: item.id,
                            displayOrder: item.displayOrder - 1
                        }
                        console.log('__input', input)
                        const resUpdate = await API.graphql(graphqlOperation(updateStock, {input}))

                    }
                }
            }
            

        }catch(err){
            console.log('error', err)
        }
    }

    const getOrderedStock = async( isPublish ) => {
        let stockList = []
        try{
            let nextToken = null
            const res = await API.graphql(graphqlOperation(queryStockGsiOrderedList, {isPublish: isPublish, sortDirection:"ASC"}))
            const items = res.data.queryStockGsiOrderedList.items
            for(const item of items){
              let stockItem = item
              try{
                const photoUrl = await Storage.get(item.imageMedium[0].key)
                stockItem['photoUrl'] = photoUrl
              }catch(err){
                stockItem['photoUrl'] = null
              }
              stockList.push(stockItem) 
            }
            nextToken = res.data.queryStockGsiOrderedList.nextToken
    
            while(nextToken!==null){
              const res = await API.graphql(graphqlOperation(queryStockGsiOrderedList, {isPublish: isPublish, sortDirection:"ASC", nextToken: nextToken}))
              const items = res.data.queryStockGsiOrderedList.items
              for(const item of items){
                let stockItem = item
                try{
                  const photoUrl = await Storage.get(item.imageMedium[0].key)
                  stockItem['photoUrl'] = photoUrl
                }catch(err){
                  stockItem['photoUrl'] = null
                }
                stockList.push(stockItem) 
              }
              nextToken = res.data.queryStockGsiOrderedList.nextToken
            }
    
            console.log('stockList', stockList)
    
          }catch(err){
            console.log('error', err)
          }
          return stockList
    }


    const changeIsPublish = (checked) =>{
        if(checked){
            setStock({ ...stock, 'isPublish': 1})
        }else{
            setStock({ ...stock, 'isPublish': 0, 'displayOrder': 0})
        }
    }

    const changeIsRental = (checked) =>{
        if(checked){
            setStock({ ...stock, 'isRental': 1})
        }else{
            setStock({ ...stock, 'isRental': 0})
        }
    }

    return (
        <>
            {/* header */}
            <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                <Container fluid>
                <div className="header-body">
                    <Row className="align-items-center py-4">
                    <Col lg="6" xs="7">
                        <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                            在庫詳細
                        </h6>{" "}
                    </Col>
                    </Row>
                </div>
                </Container>
            </div>

            <Container className="mt--6" fluid>
                <Row>
                    <Col xs="6">
                        <Card className="mb-4">
                            <CardBody>
                            <Form>
                                <Row>
                                    <Col xs="2">
                                        <FormGroup>
                                        <label className="custom-toggle mr-1">
                                        <input 
                                            // defaultChecked 
                                            type="checkbox" 
                                            checked={stock.isPublish===1}
                                            onChange={e => changeIsPublish(e.target.checked)}
                                        />
                                        <span
                                            className="custom-toggle-slider rounded-circle"
                                            data-label-off="非公開"
                                            data-label-on="公開"
                                        />
                                        </label>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="4">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="displayOrder"
                                        >
                                            表示順
                                        </label>
                                        <Input 
                                            id="displayOrder" 
                                            type="select"
                                            value={stock.displayOrder}
                                            onChange={e => setStock({ ...stock, 'displayOrder': e.target.value})}
                                            disabled={stock.isPublish===0}
                                        >
                                            {stockId==="new" ? <option key='0' value='0'>指定なし</option> : null }
                                            {stockOrders.map((stockOrder) => {
                                                return (<option key={stockOrder} value={stockOrder}>{stockOrder}</option>);
                                            })}
                                        </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="6">
                                        <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="status"
                                        >
                                            Status
                                        </label>
                                            <Input
                                                id="status"
                                                multiple="multiple"
                                                type="select"
                                                onChange={e => setStock({ ...stock, 'status': e.target.value})}
                                            >
                                                <option value='None' selected={stock.status===''}></option>
                                                <option value='New' selected={stock.status==='New'}>PickUp</option>
                                                <option value='Hold' selected={stock.status==='Hold'}>Hold</option>
                                                <option value='Sold' selected={stock.status==='Sold'}>Sold</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr />
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="category"
                                >
                                    Category
                                </label>
                                    <Input
                                        id="category"
                                        multiple="multiple"
                                        type="select"
                                        onChange={e => setStock({ ...stock, 'category': e.target.value})}
                                    >
                                        <option value='Excavators' selected={stock.category==='Excavators'}>Excavators</option>
                                        <option value='MiniExcavators' selected={stock.category==='MiniExcavators'}>Mini Excavators</option>
                                        <option value='Bulldozers' selected={stock.category==='Bulldozers'}>Bulldozers</option>
                                        <option value='WheelLoaders' selected={stock.category==='WheelLoaders'}>Wheel Loaders</option>
                                        <option value='Rollers' selected={stock.category==='Rollers'}>Rollers</option>
                                        <option value='MotorGraders' selected={stock.category==='MotorGraders'}>Motor Graders</option>
                                        <option value='AsphaltFinishers' selected={stock.category==='AsphaltFinishers'}>Asphalt Finishers</option>                               
                                        <option value='Cranes' selected={stock.category==='Cranes'}>Cranes</option>
                                        <option value='Generators' selected={stock.category==='Generators'}>Generators</option>
                                        <option value='AirCompressors' selected={stock.category==='AirCompressors'}>Air Compressors</option>
                                        <option value='Welders' selected={stock.category==='Welders'}>Welders</option>
                                        <option value='Trucks' selected={stock.category==='Trucks'}>Trucks</option>
                                        <option value='Vehicles' selected={stock.category==='Vehicles'}>Vehicles</option>
                                        <option value='AerialPlatforms' selected={stock.category==='AerialPlatforms'}>AerialPlatforms</option>
                                        <option value='Forklifts' selected={stock.category==='Forklifts'}>Forklifts</option>
                                        <option value='Attachments' selected={stock.category==='Attachments'}>Attachments</option>
                                        <option value='Others' selected={stock.category==='Others'}>Others</option>
                                    </Input>
                                </FormGroup>  
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="maker"
                                >
                                    Maker
                                </label>
                                <Input
                                    id="maker"
                                    placeholder=""
                                    type="text"
                                    onChange={e => setStock({ ...stock, 'maker': e.target.value})}
                                    value={stock.maker}
                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="model"
                                >
                                    Model
                                </label>
                                <Input
                                    id="model"
                                    placeholder=""
                                    type="text"
                                    onChange={e => setStock({ ...stock, 'model': e.target.value})}
                                    value={stock.model}
                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="serial"
                                >
                                    Serial
                                </label>
                                <Input
                                    id="serial"
                                    placeholder=""
                                    type="text"
                                    onChange={e => setStock({ ...stock, 'serial': e.target.value})}
                                    value={stock.serial}
                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="year"
                                >
                                    Year
                                </label>
                                <Input
                                    id="year"
                                    placeholder=""
                                    type="text"
                                    onChange={e => setStock({ ...stock, 'year': e.target.value})}
                                    value={stock.year}
                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="hour"
                                >
                                    Hour
                                </label>
                                <Input
                                    id="hour"
                                    placeholder=""
                                    type="text"
                                    onChange={e => setStock({ ...stock, 'hour': e.target.value})}
                                    value={stock.hour}
                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="port"
                                >
                                    Place
                                </label>
                                <Input
                                    id="port"
                                    placeholder=""
                                    type="text"
                                    onChange={e => setStock({ ...stock, 'port': e.target.value})}
                                    value={stock.port}
                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="price"
                                >
                                    Price<small> (-1で「ASK」が表示されます [注]statusの設定は無視されます) </small>
                                </label>
                                <Input
                                    id="price"
                                    placeholder=""
                                    type="number"
                                    onChange={e => setStock({ ...stock, 'price': e.target.value})}
                                    value={stock.price}
                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="gk"
                                >
                                    GK
                                </label>
                                <Input
                                    id="gk"
                                    placeholder=""
                                    type="number"
                                    onChange={e => setStock({ ...stock, 'gk': e.target.value})}
                                    value={stock.gk}
                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="youtubeUrl"
                                >
                                    Youtube<small> (例：https://https://youtu.be/xxxxxx) </small>
                                </label>
                                <Input
                                    id="youtubeUrl"
                                    placeholder=""
                                    type="text"
                                    onChange={e => setStock({ ...stock, 'youtubeUrl': e.target.value})}
                                    value={stock.youtubeUrl}
                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="condition"
                                    style={{ marginRight: '25px' }}
                                >
                                    Rantal
                                </label>
                                <Input
                                    id="isRental"
                                    type="checkbox"
                                    checked={stock.isRental===1}
                                    onChange={e => changeIsRental(e.target.checked)}
                                    value={stock.isRental}

                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="condition"
                                >
                                    Condition
                                </label>
                                <Input
                                    id="condition"
                                    rows="5"
                                    type="textarea"
                                    onChange={e => setStock({ ...stock, 'condition': e.target.value})}
                                    value={stock.condition}
                                />
                                </FormGroup>
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="memo"
                                >
                                    Memo
                                </label>
                                <Input
                                    id="memo"
                                    rows="5"
                                    type="textarea"
                                    onChange={e => setStock({ ...stock, 'memo': e.target.value})}
                                    value={stock.memo}
                                />
                                </FormGroup>
                                <div align='center'>
                                    <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => handleSubmit()}
                                    >
                                    save
                                    </Button>
                                </div>
                            </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    {stockId==='new' ? null              
                        :   
                        <Col xs="6">
                            {/* <Card className="mb-4">
                                <CardBody> */}
                                    <FileUploadForm stockId={stockId}/>
                                {/* </CardBody>
                            </Card> */}
                        </Col>
                    }
                </Row>
            </Container>
        </>
    )
}

export default StockDetailPage;